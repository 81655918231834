import { routes, notFound } from "./urls";
import Middleware from './middleware';
import { onlyNumbers } from 'utils/validator';

const root = document.getElementById('root');

const pathToRegex = path => new RegExp("^" + path.replace(/\//g, "\\/").replace(/:\w+/g, "(.+)") + "$");
var MAIN_VIEW = null;

const getParams = (match, params) => {
  const values = match.result.slice(1);
  const keys = Array.from(match.route.path.matchAll(/:(\w+)/g)).map(result => result[1]);

  let props = Object.fromEntries(keys.map((key, i) => {
    return [key, values[i]];
  }));
  
  return { ...props, ...params };
};

const navigateTo = async(url, params) => {
  sessionStorage.removeItem('page-state');
  let path = await Middleware(url);
  history.pushState(null, null, path);
  router(params, true);
};

const openNewTab = (url) => {
  let save = sessionStorage.getItem('page-state');
  sessionStorage.removeItem('page-state');
  window.open(url, '_blank');
  sessionStorage.setItem('page-state', save);
}

const navigateNextSection = async (view, params) => {
  sessionStorage.removeItem('page-state');
  const $view = new view(params);
  root.innerHTML = "";
  root.appendChild(await $view.getElement());
}

const extraData = (params) => {
  let url_params = location.search.substr(1).split('&');
  url_params.forEach(param => {
    let data = param.split('=');
    let value = onlyNumbers(data[1]) ? parseInt(data[1]) : data[1];
    params[data[0]] = value;
  });
}

const router = async (params = {}, fromNavigator = false) => {
  g_loading().toggle(true);
  extraData(params);
  if(!fromNavigator){
    let path = await Middleware(location.pathname);
    let vars = location.search
    history.pushState(null, null, path + vars);
  }

  const potentialMatches = routes.map(route => {
    return {
      route: route,
      result: location.pathname.match(pathToRegex(route.path)),
    };
  });

  let match = potentialMatches.find(potentialMatch => potentialMatch.result !== null);

  if (!match) {
    match = {
      route: notFound,
      result: [location.pathname],
    };
  }

  let view = await match.route.getView();
  g_loading().toggle(false);
  
  if (MAIN_VIEW) MAIN_VIEW.destroy();
  MAIN_VIEW = new view(getParams(match, params), match.route.navSettings);

  root.innerHTML = "";
  root.appendChild(await MAIN_VIEW.getElement());
};

export {
  router,
  navigateTo,
  navigateNextSection,
  openNewTab,
}

