import { NAV_STATES, WEB_MODULES } from 'utils/const';
import $PRIVILEGES from 'privilege_manager/privileges';

export const routes = [
  {
    path: '/',
    getView: async () => (await import('screens/login')).default,
    navSettings: {
      states: [],
      module: WEB_MODULES.NONE
    },
    privilege: 'none'
  },
  {
    path: '/status',
    getView: async () => (await import('screens/status')).default,
    navSettings: {
      states: [],
      module: WEB_MODULES.NONE
    },
    privilege: 'none'
  },
  { path: '/cambiar-contrasena',
    getView: async () => (await import('screens/change_password')).default,
    navSettings: {
      states: [],
      module: WEB_MODULES.NONE
    },
    privilege: 'none'
  },
  // KNOWLEDGE BASE
  { 
    path: '/lista_de_base_de_conocimientos',
    getView: async () => (await import('screens/knowledgeBase/index')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.SIDE_BAR],
      module: WEB_MODULES.KNOWLEDGE_BASE
    },
    privilege: [$PRIVILEGES.boe_kb, $PRIVILEGES.standard_kb],
  },
  {
    path: '/crear_base_conocimiento',
    getView: async () => (await import('screens/knowledgeBase/create')).default,
    navSettings: {
      states: [NAV_STATES.BACK, NAV_STATES.NAV_BAR],
      module: WEB_MODULES.KNOWLEDGE_BASE,
      customBack: true
    },
    privilege: $PRIVILEGES.boe_kb,
  },
  {
    path: '/crear_base_conocimiento_metadatos',
    getView: async () => (await import('screens/knowledgeBase/automatic/create')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.KNOWLEDGE_BASE,
      customBack: true
    },
    privilege: $PRIVILEGES.boe_kb,
  },
  {
    path: '/crear-bc-manual',
    getView: async () => (await import('screens/knowledgeBase/manual/create')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.KNOWLEDGE_BASE,
      customBack: true
    },
    privilege: $PRIVILEGES.standard_kb,
  },
  {
    path: '/crear_base_conocimiento_revision',
    getView: async () => (await import('screens/knowledgeBase/automatic/panel')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.KNOWLEDGE_BASE,
      customBack: true
    },
    privilege: $PRIVILEGES.boe_kb,
  },
  {
    path: '/actualizar_base_conocimiento',
    getView: async () => (await import('screens/knowledgeBase/automatic/updateLaw')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.KNOWLEDGE_BASE,
      customBack: true
    },
    privilege: $PRIVILEGES.boe_kb,
  },
  {
    path: '/redactar-base-de-conocimiento',
    getView: async () => (await import('screens/knowledgeBase/manual/panel')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.KNOWLEDGE_BASE,
      customBack: true
    },
    privilege: $PRIVILEGES.standard_kb,
  },
  // QUESTIONS
  {
    path: '/preguntas',
    getView: async () => (await import('screens/questions/list')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.SIDE_BAR],
      module: WEB_MODULES.QUESTIONS
    },
    privilege: $PRIVILEGES.questions,
  },
  {
    path: '/crear-pregunta',
    getView: async () => (await import('screens/questions/create_question')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.QUESTIONS,
      customBack: true
    },
    privilege: $PRIVILEGES.questions,
  },
  {
    path: '/recursos',
    getView: async () => (await import('screens/questions/creation-panel')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.QUESTIONS,
      customBack: true
    },
    privilege: $PRIVILEGES.questions,
  },
  {
    path: '/importacion',
    getView: async () => (await import('screens/questions/import')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.QUESTIONS,
      customBack: true
    },
    privilege: $PRIVILEGES.questions,
  },
  {
    path: '/vista-previa',
    getView: async () => (await import('screens/knowledgeBase/kb_view')).default,
    navSettings: {
      states: [],
      module: WEB_MODULES.QUESTIONS
    },
    privilege: $PRIVILEGES.questions,
  },
  // PROGRAMS AND PREFABS
  {
    path: '/lista_de_programas_y_prefabs',
    getView: async () => (await import('screens/pr_pf_list')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.SIDE_BAR],
      module: WEB_MODULES.PROGRAMS
    },
    privilege: $PRIVILEGES.programs,
  },
  {
    path: '/crear_programa_o_prefab',
    getView: async () => (await import('screens/create_pr_pf')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.PROGRAMS,
      customBack: true
    },
    privilege: $PRIVILEGES.programs,
  },
  {
    path: '/programas_o_prefabs',
    getView: async () => (await import('screens/program_prefab')).default,
    navSettings: {
      states: [NAV_STATES.NAV_BAR, NAV_STATES.BACK],
      module: WEB_MODULES.PROGRAMS,
      customBack: true
    },
    privilege: $PRIVILEGES.programs,
  },
  {
    path: '/cms',
    getView: async () => (await import('screens/no_module')).default,
    navSettings: {
      states: [],
      module: WEB_MODULES.NONE
    },
    privilege: 'no_page',
  },
];

export const notFound = {
  path: '/no-se-encontro-la-pagina',
  getView: async () => (await import('screens/not_found')).default,
  navSettings: {
    states: [],
    module: WEB_MODULES.NONE
  },
}
