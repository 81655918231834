import { getStorage, setStorage } from './index';
import { AUTH } from './../type';

const KEY = 'AUTH';

const initialState = {
  user: {},
  token: '',
  changePass: false,
  privileges: [],
  authenticated: false,
  remember_me: false,
}

const emptyAction = { type: '---' };

const authReducer = (action = emptyAction, state = getStorage(KEY, initialState)) => {
  switch (action.type) {
    case AUTH.LOGIN: {
      let { login_data, privileges } = action.payload;
      let new_state = {
        user: {
          pid: parseInt(login_data.person_id),
          email: login_data.email,
          nick: login_data.nick,
        },
        token: login_data.session_token,
        changePass: login_data.must_change_pwd,
        privileges: privileges,
        authenticated: true,
      }
      setStorage(KEY, new_state);
      return new_state;
    }
    case AUTH.REMEMBER_ME: {
      let { remember_me } = action.payload;
      let new_state = {
        ...state,
        remember_me
      }
      setStorage(KEY, new_state);
      return new_state;
    }
    case AUTH.UPDATE_PRIVILEGES: {
      let { privileges } = action.payload;
      let new_state = {
        ...state,
        privileges: privileges,
      }
      setStorage(KEY, new_state);
      return new_state;
    }
    case AUTH.LOGOUT: {
      localStorage.removeItem(KEY);
      break;
    }
    default: return state;
  }
}

export default authReducer;

