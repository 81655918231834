import { html } from 'lit-html';
import styles from './paginate-selector.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-paginate-selector";

class PaginateSelectorComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  onInit() { 
    this.__show ??= false;
    this.__list ??= [];
    document.addEventListener('click', this.handleClickOutside);
  }

  onDestroy() { 
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const path = event.composedPath();
    const element = path.find(element => element.tagName === ComponentTagName.toUpperCase());

    if (element !== this) this.close(); 
  }

  static get observedAttributes() {
    return ['label'];
  }

  get label() { return this.getAttribute('label'); }
  set label(value) { this.setAttribute('label', value); }

  close() {
    this.__show = false;
    this.render();
  }

  toggle() {
    this.__show = !this.__show;
    this.render();
  }

  loadList(list){
    this.__list = list;
    this.render();
  }

  updateIndex(index){
    this.__list.forEach((item, i) => {
      item.active = i == index;
    });
    this.render();
  }

  __itemClicked(event) {
    const index = event.target.dataset.index;
    
    this.__show = false;
    this.__list.forEach((item, i) => {
      item.active = i == index;
    });

    const itemSelected = this.__list[index];
    this.dispatchEvent(new CustomEvent('paginate-selector-item-clicked', { 
      detail: { itemSelected, index },
      bubbles: true,
      composed: true
    }));
    this.render();
  }

  renderList() {
    const label = this.label ?? 'pregunta';
    const list = this.__list ?? [];

    return list.map((item, index) => {
      return html`
      <p @click="${(e) => this.__itemClicked(e)}"
        data-index="${index}" 
        class="${item.active ? 'active': ''}" >
        ${label} ${index + 1}
      </p>`;
    });
  }

  template() {
    this.__show ??= false;
    this.__list ??= [];
    const currentSelectionIndex = this.__list.findIndex(item => item.active);

    return /* template */html`
      <div class="ml-paginate-selector ${this.__show ? 'show' : ''} ${this.__list.length ? '' : 'disabled'}">
        <div @click="${(e) => this.toggle()}" class="button-selector-wrap">
          <p class="label font-bold">
            ${currentSelectionIndex < 0 ? 0 : currentSelectionIndex + 1}/${this.__list.length}
          </p>
          <img 
            src="assets/icons/b/flecha_desplegar.svg" 
            width="20" 
            alt="arrow-icon"/>
        </div>

        <div class="options-wrap">
          <div class="box"> 
            ${this.renderList()}
          </div>
        </div>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, PaginateSelectorComponent);
