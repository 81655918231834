export const formatList = (list, all, pid) => {
  let new_list = list.filter(item => all || item.pid == pid)

  return new_list.map(item => {
    let date_lud = new Date(Date.parse(item.lud));
    let date_dat = new Date(Date.parse(item.dat));
    return {
      ...item,
      dat: date_dat.toLocaleString(),
      lud: date_lud.toLocaleString(),
    }
  })
}

