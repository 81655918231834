import { html } from 'lit-html';
import styles from './button.styles.scss';
import WebComponent from 'utils/web-component';

export const BUTTON_STATES = {
  NORMAL: 'normal',
  LOADING: 'loading',
  DISABLED: 'disabled',
}

const ComponentTagName = "ml-button-v2";

class ButtonComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
  }

  onInit() { }

  onDestroy() { }

  static get observedAttributes() {
    return ['type', 'color', 'mode'];
  }

  get type() { return this.getAttribute('type'); }
  set type(value) { this.setAttribute('type', value); }

  get color() { return this.getAttribute('color'); }
  set color(value) { this.setAttribute('color', value); }

  get mode() { return this.getAttribute('mode'); }
  set mode(value) { this.setAttribute('mode', value); }

  __clickHandler(event){
    this.dispatchEvent(new CustomEvent('btnClick', { 
      detail: this,
      bubbles: true,
      composed: true,
    }));
  }

  template() {
    const type = this.type ?? 'button';
    const color = this.color ?? 'primary';
    const mode = this.mode ?? BUTTON_STATES.NORMAL;

    return /* template */html`
      <button @click="${(e) => this.__clickHandler()}" class="ml-button ${color} ${mode}" type="${type}">
        <i class="icon-1 ml-icon-actualizar text-primary"></i>
        <slot class="button-content" style="font-family: 'Spartan'; text-transform: lowercase"></slot>
      </button>
      `;
  }
}

window.customElements.define(ComponentTagName, ButtonComponent);
