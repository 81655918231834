export const getLawVersions = (json) => {
  return [ ...json.versions ];
}

export const lawAlert = (json) => {
  return {
    last_law_update: json.lud,
    alert_node_id: json.iid,
    alert_quantity: json.cnt,
    alert_json: json.data
  }
}

export const resolveAlert = (json) => {
  return {
    last_law_update: json.lud,
    alert_quantity: json.cnt
  }
}

export const lawInfo = (json) => {
  let date = new Date(json.llud + " ");
  let dateForLud = new Date(json.lud);
  return {
    auid: json.auid,
    creation_date: json.dat,
    last_update: dateForLud.toLocaleString('ES'),
    short_name: json.sn,
    law_state: json.sts,
    // BOE INFO
    entry_into_force: json.ent,
    last_law_update: date.toLocaleString("ES"),
    published: json.pub,
    name: json.nm,
    url: json.url,
    permalink: json.urlp,
  }
}

export const updateLawInfo = (json) => {
  return {
    last_update: json.lud
  }
}

export const law = (json) => {
  return {
    law_quantity: json.cnt,
    law_json: json.data,
    last_law_update: json.lud,
    name: json.nm
  }
}

export const solveLawError = (json) => {
  return {
    last_law_update: json.lud,
    alert_quantity: json.cnt
  }
}

