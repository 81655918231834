import { html } from 'lit-html';
import styles from './menu-btn.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-layout-menu-btn";

class LayoutMenuBtn extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  onInit() {
    this.__options = [];
    this.__collapse = false;
    document.addEventListener('click', this.handleClickOutside);
  }

  onDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const path = event.composedPath();
    const mlLayoutMenuBtn = path.find(element => element.tagName === ComponentTagName.toUpperCase());

    if (mlLayoutMenuBtn !== this) this.__hideOptions();
  }

  static get observedAttributes() {
    return ['data', 'notifications'];
  }

  set data(value) { this.setAttribute('data', value); }
  get data() { return this.getAttribute('data'); }

  set notifications(value) { this.setAttribute('notifications', value); }
  get notifications() { return this.getAttribute('notifications'); }

  __hideOptions() {
    let data = JSON.parse(this.data);

    if(data.type === 'dropdown') {
      this.__collapse = false;
      this.render();
    }
  }

  __menuClicked(event){
    let data = JSON.parse(this.data);
    
    if (data.type === 'dropdown') {
      this.__collapse = !this.__collapse;
      this.render();
    }

    if (data.type === 'simple') {  }
  }

  setOptions(options) {
    this.__options = options;
    this.render();
  }

  template() {
    const notifications = this.notifications ?? 0;
    const notificationsClass = (notifications == 0) ? 'hidden' : 'notification-number';
    let data = { icon: "", text: "" };
    if (this.data) data = JSON.parse(this.data);

    return /* template */html`
      <div @click=${ (e) => this.__menuClicked(e)}
        class="nav-bar-btn ${ this.__collapse ? 'active' : '' }">
        <img 
          class="nav-bar-btn-img"
          src="assets/icons/sidebar/${data.icon}.svg" 
          width="25" 
          alt="notification-btn" />
        <span class="nav-bar-btn-text">${data.text}</span>
        <span class="${ notificationsClass }"> ${ notifications } </span>

        <div class="nav-bar-btn-options ${ this.__collapse ? '' : 'hidden' }">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

window.customElements.define(ComponentTagName, LayoutMenuBtn);
