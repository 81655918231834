import CryptoJS from 'crypto-js';
import authReducer from './auth';
import { ENV } from 'utils/env';

const reducers = {
  authReducer,
}

export const getStorage = (key, state) => {
  if(localStorage.getItem(key)){
    return _decrypt(key);
  }else{
    localStorage.setItem(key, _encrypt(state));
    return state;
  }
}

export const setStorage = (key, state) => {
  localStorage.setItem(key, _encrypt(state));
}

export const runAllReducers = (data) => {
  Object.entries(reducers).forEach(([key, action]) => {
    action(data);
  });
}

const _encrypt = (data) => {
  data = JSON.stringify(data);
  if(!ENV.DEBUGGER){
    data = CryptoJS.AES.encrypt(data, ENV.CRYPT_KEY).toString(); 
  }
  return data;
}

const _decrypt = (key) => {
  let data = localStorage.getItem(key);
  if(!ENV.DEBUGGER){
    let bytes  = CryptoJS.AES.decrypt(data, ENV.CRYPT_KEY);
    data = bytes.toString(CryptoJS.enc.Utf8);
    data = data.length > 0 ? data : "{}";
  }
  return JSON.parse(data);
}

