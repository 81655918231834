import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { obj as url } from './../../urls';

export const beginEdit = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.beginEdit, data),
    (s_data) => { if(success) success(s_data.beginEdit) },
    (s_error) => { if(error) error(s_error) },
    { api: url.beginEdit, data }
  )
}

export const setOwnership = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.setOwnership, data),
    (s_data) => success(s_data.setOwnership),
    (s_error) => error(s_error),
    { api: url.setOwnership, data }
  )
}

