import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import * as $f from './format';
import { knowledge as URL } from './../../urls';

export const getVersionsLaw = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.getVersionsLaw, data),
    (dataSuccess) => success($f.getLawVersions(dataSuccess.getVersionsLaw)),
    (dataError) => error(dataError),
    { api: URL.getVersionsLaw, data }
  );
}

export const getReferenceText = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.getReferenceText, data),
    (resp) => { if(success) success(resp.getReferenceText) },
    (e) => { if(error) error(e) },
    { api: URL.getReferenceText, data }
  );
}

export const getLawAlert = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.getLawAlert, data),
    (dataSuccess) => success($f.lawAlert(dataSuccess.getLawAlert)),
    (dataError) => error(dataError),
    { api: URL.getLawAlert, data }
  );
}

export const resolveLawAlert = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.resolveLawAlert, data),
    (dataSuccess) => success($f.resolveAlert(dataSuccess.resolveLawAlert)),
    (dataError) => error(dataError),
    { api: URL.resolveLawAlert, data }
  );
}

export const getLaw = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.getLaw, data),
    (resp) => { if(success) success($f.law(resp.getLaw)) },
    (e) => { if(error) error(e) },
    { api: URL.getLaw, data }
  )
}

export const getLawInfo = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.getLawInfo, data),
    (resp) => { if(success) success($f.lawInfo(resp.getLawInfo)) },
    (e) => { if(error) error(e) },
    { api: URL.getLawInfo, data }
  );
}

export const updateLawInfo = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.updateLawInfo, data),
    (dataSuccess) => success($f.updateLawInfo(dataSuccess.updateLawInfo)),
    (dataError) => error(dataError),
    { api: URL.updateLawInfo, data }
  );
}

export const resolveLawError = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.resolveLawError, data),
    (dataSuccess) => success($f.solveLawError(dataSuccess.resolveLawError)),
    (dataError) => error(dataError),
    { api: URL.resolveLawError, data }
  )
}

export const importKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.importKb, data),
    (dataSuccess) => success(dataSuccess),
    (dataError) => error(dataError),
    { api: URL.importKb, data }
  )
}

export const rejectKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.rejectKb, data),
    (s_data) => success(s_data.kb_rejectKb),
    (dataError) => error(dataError),
    { api: URL.rejectKb, data }
  )
}

export const revisionKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.revisionKb, data),
    (s_data) => success(s_data.revisionKb),
    (dataError) => error(dataError),
    { api: URL.revisionKb, data }
  )
}

export const updateItemKb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.updateItemKb, data),
    (resp) => { if(success) success(resp.updateItemKb) },
    (e) => { if(error) error(e) },
    { api: URL.updateItemKb, data }
  )
}

export const disableItemKb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.disableItemKb, data),
    (resp) => { if(success) success(resp.disableItemKb) },
    (e) => { if(error) error(e) },
    { api: URL.disableItemKb, data }
  )
}

export const createItemKb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.createItemKb, data),
    (resp) => { if(success) success(resp.createItemKb) },
    (e) => { if(error) error(e) },
    { api: URL.createItemKb, data }
  )
}

export const updateKb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.updateKb, data),
    (resp) => { if(success) success(resp.updateKb) },
    (e) => { if(error) error(e) },
    { api: URL.updateKb, data }
  )
}

export const updateMetaDataKb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.updateMetaDataKb, data),
    (resp) => { if(success) success(resp.updateMetaDataKb) },
    (e) => { if(error) error(e) },
    { api: URL.updateMetaDataKb, data }
  )
}

