import { checkPrivilege, getPrivileges } from './login';
import { c_msg } from 'utils/console';

export const getPrivilege = (privilege, callback, pid) => {
  checkPrivilege({ pid, privilege: privilege },
    (successData) => callback({
      permit: successData.result === 'ok',
      attr: successData.attributes,
    }),
    (errorData) => c_msg('api/utils:10', errorData),
  )
}

const allPrivileges = (pid, callback) => {
  let privileges = [];
  getPrivileges({ pid },
    (successData) => {
      successData.privileges.forEach(data => {
        privileges.push({
          privilege: data.privilege_id,
          attr: data.grant_attributes
        });
      });
      callback(privileges);
    },
    (errorData) => console.error({ errorData })
  );
}

const hasPrivilege = (array, privilege) => {
  let obj = array.find(e => e.privilege === privilege);
  return {
    permit: obj !== undefined,
    attr: obj !== undefined ? obj.attr : {}
  };
}

export const checkPrivileges = (privileges, callback, pid) => {
  allPrivileges(pid, (privilege_list) => {
    let resp = {};
    privileges.forEach(e => {
      resp[e] = hasPrivilege(privilege_list, e);
    });
    callback(resp);
  });
}
