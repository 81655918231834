import { loginRequest as $api } from 'api_connection';
import $PRIVILEGES from 'privilege_manager/privileges';
import { loginActions as $a } from 'storage/actions';
import authReducer from 'storage/reducers/auth';
import { routes } from "./urls";

const ROOT = '/';

const PAGES = [
   $PRIVILEGES.boe_kb,
   $PRIVILEGES.standard_kb,
   $PRIVILEGES.questions,
   $PRIVILEGES.programs,
   $PRIVILEGES.prefabs
]

const PAGES_PATH = {
  boe_kb: '/lista_de_base_de_conocimientos',
  standard_kb: '/lista_de_base_de_conocimientos',
  questions: '/preguntas',
  programs: '/lista_de_programas_y_prefabs',
  prefabs: '/lista_de_programas_y_prefabs',
}

const Middleware = async(path) => {
   let { authenticated, remember_me, user, token, privileges } = authReducer();
   let sS_remember_me = sessionStorage.getItem('remember_me');

   if(path == '/status') return path;

   if(authenticated && (remember_me || sS_remember_me)){
      return path === ROOT ? _getDashboard(privileges) : _getPath(privileges, path);
   }

   if (!Boolean(user?.pid) || !Boolean(token.length)) return ROOT;

   let sessionData = await getRememberMe(user.pid, token);
   if(!sessionData.remember_me) return ROOT;

   return path;
}

const getRememberMe = (pid, ses) => new Promise((resolve) => {
   $api.sessionGetData({ pid: `${pid}`, ses },
      (resp) => {
         if(resp.remember_me){
            sessionStorage.setItem('remember_me', resp.remember_me);
         }else{
            $a.logoutAction();
         }
         resolve(resp);
      },
      (error) => console.error(error)
   );
});

const _getDashboard = (privileges) => {
   let _privileges = privileges.map(privilege => privilege.privilege_id);
   for(let i = 0; i < PAGES.length; i++){
      if(_privileges.includes(PAGES[i])){
         return PAGES_PATH[PAGES[i]];
      }
   }
   return '/cms';
}

const _getPath = (privileges, path) => {
   let params = path.split('?')[1] ? `?${path.split('?')[1]}` : ''; 
   path = path.split('?')[0];
   let route = routes.find(p => p.path === path);
   let _privileges = privileges.map(privilege => privilege.privilege_id);
   if(route.privilege === 'none') return path + params;
   if(Array.isArray(route.privilege)){
      let hasPrivileges = route.privilege.some(i => _privileges.includes(i))
      if(hasPrivileges) return path + params;
   }
   if(_privileges.includes(route.privilege)) return path + params;
   return _getRootPage(_privileges);
}

const _getRootPage = (privileges) => {
  for(let i = 0; i < PAGES.length; i++){
    if(privileges.includes(PAGES[i])){
      return PAGES_PATH[PAGES[i]];
    }
  }
  return '/cms';
}


export default Middleware;

