import { ENV } from "utils/env";

export const BASE_URL= ENV.API_URL;

export const APP = {
  KNOWLEDGE: `knowledge/` 
}

export const account = {
  status: 'status',
  login: 'account/login',
  logout: 'account/logout',
  forgotPassword: 'account/passwordForgot',
  checkPrivilege: 'account/checkPrivilege',
  getPrivileges: 'account/getPrivileges',
  userSetAttributes: 'account/usrSetAttributes',
  sessionSetData: 'account/v2/sessionSetData',
  sessionGetData: 'account/v2/sessionGetData',
}

export const knowledge = {
  getLawFromURL: 'knowledge/getLawFromURL',
  createLawFromURL: 'knowledge/createLawFromURL',
  getVersionsLaw: 'knowledge/getVersionsLaw',
  getReferenceText: 'knowledge/getReferenceText',
  getLawAlert: 'knowledge/getLawAlert',
  resolveLawAlert: 'knowledge/resolveLawAlert',
  getLaw: 'knowledge/getLaw',
  getLawInfo: 'knowledge/getLawInfo',
  updateLawInfo: 'knowledge/updateLawInfo',
  resolveLawError: 'knowledge/resolveLawError',
  saveLaw: '/knowledge/saveLaw',
  getKbList: 'knowledge/getKbList',
  importKb: 'knowledge/importKb',
  rejectKb: 'knowledge/rejectKb',
  revisionKb: 'knowledge/revisionKb',
  deleteKb: 'knowledge/deleteKb',
  getKbSourceList: 'knowledge/getKbSourceList',
  getKbTypeList: 'knowledge/getKbTypeList',
  updateItemKb: 'knowledge/updateItemKb',
  disableItemKb: 'knowledge/disableItemKb',
  createItemKb: 'knowledge/createItemKb',
  updateKb: 'knowledge/updateKb',
  updateMetaDataKb: 'knowledge/updateMetaDataKb',
}

export const manual_kb = {
  createKb: 'knowledge/createKb',
  getKb: 'knowledge/getKb',
  getKbInfo: 'knowledge/getKbInfo',
  updateKbInfo: 'knowledge/updateKbInfo',
  saveKb: 'knowledge/saveKb',
  kbPreview: 'knowledge/getKbPreview',
  saveHtmlToKb: 'knowledge/saveHtmlToKb',
}

export const prg_pf = {
  createPrPf: 'programs/createPrPf',
  getPrPfList: 'programs/getPrPfList',
  getPrPfInfo: 'programs/getPrPfInfo',
  updatePrPfInfo: 'programs/updatePrPfInfo',
  createItemPrPf: 'programs/createItemPrPf',
  updateItemPrPf: 'programs/updateItemPrPf',
  deleteItemPrPf: 'programs/deleteItemPrPf',
  convertToPrefab: 'programs/convertToPrefab',
  clonePrefab: 'programs/clonePrefab',
  getKbList: 'programs/getKbList',
  getPrefabList: 'programs/getPrefabList',
  revisionPrPf: 'programs/revisionPrPf',
  rejectPrPf: 'programs/rejectPrPf',
  publishPrPf: 'programs/publishPrPf',
  unlinkNodeFromPf: 'programs/unlinkNodeFromPf',
  deletePrPf: 'programs/deletePrPf',
}

export const obj = {
  getItemTypes: 'objects/getItemTypes',
  getObject: 'objects/getObject',
  getTree: 'objects/getTree',
  beginEdit: 'objects/beginEdit',
  setOwnership: 'objects/setOwnership',
  getBreadcrumb: 'objects/getBreadcrumb',
  getNameList: 'objects/getNameList',
}

export const users = {
  getEditorList: 'users/getEditorList',
  setEditorsToObject: 'users/setEditorsToObject',
  getEditorListFromObject: 'users/getEditorListFromObject',
}

export const questions = {
  getQuestionList: 'questions/getQuestionList',
  updateQuestion: 'questions/updateQuestion',
  createAnswer: 'questions/createAnswer',
  getKbList: 'questions/getKbList',
  deleteQuestion: 'questions/deleteQuestion',
  updateAnswer: 'questions/updateAnswer',
  statsQsFromKbLeafs: 'questions/statsQsFromKbLeafs',
  getQsTypeList: 'questions/getQsTypeList',
  getQsFromKbLeaf: 'questions/getQsFromKbLeaf',
  createQuestion: 'questions/createQuestion',
  createReference: 'questions/createReference',
  readyQuestion: 'questions/readyQuestion',
  revisionQuestion: 'questions/revisionQuestion',
  getQuestion: 'questions/getQuestion',
  updateReference: 'questions/updateReference',
  deleteReference: 'questions/deleteReference',
  deleteQsFromKb: 'questions/deleteQsFromKb',
  publishQsFromKb: 'questions/publishQsFromKb',
  rejectQuestion: 'questions/rejectQuestion',
  impGetElementList: 'questions/impGetElementList',
  impGetQuestionList: 'questions/impGetQuestionList',
  impGetSimilarQs: 'questions/impGetSimilarQs',
  impCreateQuestion: 'questions/impCreateQuestion',
  readyQsFromKb: 'questions/readyQsFromKb',
  getQuestionsData: 'questions/getQuestionsData',
}

export const makeQuestions = {
  createAIQuestionsFromKb: 'makeQuestions/createAIQuestionsFromKb',
  createAIQuestionsPh: 'makeQuestions/createAIQuestionsPh',
  getTaskStatusWorker: 'makeQuestions/getTaskStatusWorker',
  setWorkerAsSeen: 'makeQuestions/setWorkerAsSeen',
}

export const bsi = {
  log: 'bsi/logfrontend'
}

