import { toNode } from 'utils/html_creator';
import view from './layout.html';

const dialogEmpty = (data={}) => {
  let $view = toNode(view);
  Handler($view, data);
  document.querySelector('body').appendChild($view);
}

const Handler = (layout, data) => {
  let body = layout.querySelector('#cmp_dialog_empty_body');
  let card = layout.firstElementChild;

  if(data.id) layout.id = data.id;
  if(data.body) body.innerHTML = data.body;
  if(data.eventHandler) data.eventHandler(layout); 
  if(data.clsPrt) layout.classList.add(data.clsPrt);
  card.classList.add(`ml-modal-card-${data.size ? data.size : 'md'}`);
  
  layout.addEventListener('click', (e) => {
    if(e.target.matches('#cmp_dialog_empty')){
      layout.remove();
    }
  });
}

export default dialogEmpty;

