import { runAllReducers } from './../reducers';
import { loginDispatches as $d } from './../dispatch';

export const loginAction = (data) => {
  runAllReducers($d.loginDispatch(data));
}

export const logoutAction = () => {
  runAllReducers($d.logoutDispatch());
}

export const updatePrivileges = (data) => {
  runAllReducers($d.updatePrivilegeDispatch(data));
}

export const rememberMeAction = (data) => {
  runAllReducers($d.rememberMeDispatch(data));
}
