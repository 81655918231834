import authReducer from 'storage/reducers/auth';
import { loginRequest as $api } from 'api_connection';

export const allPrivileges = () => {
  const { user } = authReducer();

  return new Promise((resolve) => {
    let privileges = [];
    $api.getPrivileges({ pid: user.pid },
      (successData) => {
        successData.privileges.forEach(data => {
          privileges.push({
            privilege: data.privilege_id,
            attr: data.grant_attributes
          });
        });
        resolve(privileges);
      },
      (errorData) => console.error({ errorData })
    );
  });
}

export const getAllPrivileges = (callback) => {
  const { user } = authReducer();
  let privileges = [];
  $api.getPrivileges({ pid: user.pid },
    (successData) => {
      successData.privileges.forEach(data => {
        privileges.push({
          privilege: data.privilege_id,
          attr: data.grant_attributes
        });
      });
      callback(privileges);
    },
    (errorData) => console.error({ errorData })
  );
}

export const checkPrivilege = (privilege) => {
  const { user } = authReducer();

  return new Promise((resolve) => {
    $api.checkPrivilege({ pid: user.pid, privilege: privilege },
      (successData) => resolve({
        permit: successData.result === 'ok',
        attr: successData.attributes,
      }),
      (errorData) => resolve(false),
    )
  })
}

export const getPrivilege = (privilege, callback) => {
  const { user } = authReducer();

  $api.checkPrivilege({ pid: user.pid, privilege: privilege },
    (successData) => callback({
      permit: successData.result === 'ok',
      attr: successData.attributes,
    }),
    (errorData) => console.error({ errorData }),
  )
}

export const hasPrivilege = (array, privilege) => {
  let obj = array.find(e => e.privilege === privilege);
  return {
    permit: obj !== undefined,
    attr: obj !== undefined ? obj.attr : {}
  };
}

export const checkAttr = (hash, attr) => hash[attr] || false;

