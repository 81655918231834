import { loadData, toNode } from 'utils/html_creator';

const $toast = `<div class="ml_toast $type$">
			<p class="ml_toast_title ml_paragraph ml_strong">$title$</p>
			<p class="ml_toast_body ml_paragraph">$body$</p>
	</div>`;

const toast = (title = "Toast", body = "Mensaje", time = 3, type="success") => {
  let $view = toNode(loadData($toast, { title, body, type }));
	document.body.appendChild($view);
	setTimeout(() => $view.classList.add('show_toast'), 10);
	setTimeout(() => {
		$view.classList.remove('show_toast')
		setTimeout(() => $view.remove(), 550);
	}, 1000 * time);
}

export default toast;

