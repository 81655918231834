import { CHAR_KEYS } from 'utils/const';

export const formatKbList = (list) => {
  return list.map(item => {
    let date = new Date(item.lud);
    let extraData = item.stn.split(CHAR_KEYS.ONE);
    let newItem = {
      alerts: item.alt,
      creation_date: item.dat,
      user: item.en,
      id: item.id,
      kb_type: item.kbt,
      last_update: date.toLocaleString(),
      name: item.nm,
      source: item.srt,
      short_name: extraData[0],
      status: item.sts,
      prt: item.prt,
      pid: item.pid,
      auxObj: false,
    }

    if(extraData.length > 1){
      let { lawId } = JSON.parse(extraData[1]);
      newItem = { ...newItem, originalLawId: lawId, auxObj: true }
    }

    return newItem;
  })
}

export const formatGetKb = (json) => {
  return {
    html: json.html,
    kb_id: json.kbid,
    kb_updated: json.kbud,
    short_name: json.sn,
    status: json.sts
  }
}

