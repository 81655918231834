import "regenerator-runtime/runtime.js";
import { genericErrorRequestMsg } from './requests/formatters';
import codeInterpreter from './requests/request_map';
import { c_query, c_ok, c_error } from 'utils/console';
const OK =  'ok';

const handleRequest = async (
  request,
  success,
  fail,
  query = {},
) => {
  try {
    const {data} = await request();
    c_query('REQUEST:', query);

    /**
     * Description: SUCCESSFUL CASE
     * eg:
     * {
     *    ok: true,
     *    data: {
     *      result: 'ok'
     *      endpoint_name: {
     *        ...
     *      }
     *    }
     * }
     * */
    if(data.ok && data.data.result === OK){
      c_ok('SUCCESS:', data);
      if (success) success(data.data);
      return data.data;
    }

    /**
     * Description: ERROR WITH CODE
     * eg:
     * {
     *    ok: true,
     *    data: {
     *      result: 'code',
     *      result_msg: 'some message',
     *      user_msg: 'Algun texto',
     *      type_msg: 'standard' 'for_user' 'exception'
     *    },
     *    message: ''
     * }
     * */
    if(data.ok && data.data.result !== OK) {
      c_error('ERROR:', data);
      if (fail) fail(codeInterpreter(data, query));
      return data;
    }

    /**
     * Description: EXCEPTION CASE
     * eg:
     * {
     *    ok: false,
     *    msg: 'some message',
     *    exception_info: 'somo info'
     * }
     * */
    if(!data.ok){
      c_error('ERROR:', data);
      if (fail) fail(genericErrorRequestMsg('from_api', query)); 
      return data;
    }
  } catch (e) {
    c_error('ERROR CATCH:', e);
    
    let type = e.request?.status == 500 ? 'from_api' : 'exception';
    if(fail) fail(genericErrorRequestMsg(type, query));
    return type;
  }
};

export default handleRequest;

