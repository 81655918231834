import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { formatCreateKb } from './format';
import { manual_kb as URL } from './../../urls';
import { getPrivilege } from '../utils';
import Privilege from 'privilege_manager/privileges';
import { alert } from 'components/modals';

export const createKb = (data, success, error) => {
  getPrivilege(Privilege.create_kb, (resp) => {
    if(resp.permit){
      _createKb(data, success, error, resp);
    }else{
      alert({
        title: 'Permiso denegado',
        body: `Lo sentimos, no tienes el permiso necesario 
          para crear un base de conocimiento.`,
        btn_ok: 'salir'
      })
    }
  }, data.pid)
}

const _createKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.createKb, data),
    (s_data) => success(formatCreateKb(s_data.createKb)),
    (dataError) => error(dataError),
    { api: URL.createKb, data }
  );
}
