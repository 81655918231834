import { html } from 'lit-html';
import styles from './layout.styles.scss';
import WebComponent from 'utils/web-component';
import { STORAGE_KEYS, StorageService } from 'services/local-storage.service';
import { NAV_STATES, WEB_MODULES, WORKER_STATUS } from 'utils/const';
import { navigateTo } from 'routing';
import { loginRequest as $api, IaQuestionApi, ObjectsApi, QuestionsApi } from 'api_connection';
import { loginActions as $a } from 'storage/actions';
import "./components/menu-btn/menu-btn";
import authReducer from 'storage/reducers/auth';
import { ENV } from 'utils/env';
import { TOAST_TYPES, ToastService } from 'components/molecules/toast/toast.service';
import { QuestionPreviewModalServices } from 'components/organism/questions-preview/confirmation-msg/question-preview-modal.service';
import { c_msg } from 'utils/console';

const ComponentTagName = "ml-layout";

const NOTIFICATION_TYPES = {
  IA: 'ia-worker'
}

class Layout extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__notifications = [];
  }

  async onInit() {
    this.__config = { states: [], module: WEB_MODULES.NONE };
    this.__permissions = { kbs: false, questions: false, programs: false };
    this.__menuButtons = {
      help: { icon: 'ayuda', text: 'ayuda', type: 'simple' },
      notifications: { icon: 'notificaciones', text: 'notificaciones', type: 'dropdown' },
      profile: { icon: 'perfil', text: 'perfil', type: 'dropdown' },
    }

    const collapse = StorageService.get(STORAGE_KEYS.SIDEBAR)?.collapse;
    this.__collapse = collapse != undefined ? collapse : false;

    this.socket = io(ENV.SOCKET_URL);
    this.__startSocket();

    const result = await IaQuestionApi.read.getTaskStatusWorker({});
    await this.__loadNotifications(result.getTaskStatusWorker);
    this.render();
  }

  onDestroy() { }

  static get observedAttributes() {
    return [];
  }

  async __loadNotifications(__notifications){
    __notifications = __notifications.filter(item => {
      const correctStatus = item.status == WORKER_STATUS.SUCCESS || item.status == WORKER_STATUS.PENDING;
      const hasOid = item.parameters?.oid != undefined;
      return correctStatus && hasOid && !item.view;
    });

    const kbids = [...new Set(__notifications.map(item => item.parameters.oid))];
    const result = await ObjectsApi.read.getNameList({ oidl: kbids });

    let kbList = {}
    result.getNameList.forEach(item => kbList[item.oid] = item);

    let newNotifications = [];
    __notifications.forEach(item => newNotifications.push({
      type: NOTIFICATION_TYPES.IA,
      name: kbList[item.parameters.oid]?.nm ?? "-",
      data: {
        workId: item.work_id,
        workHash: item.id_worker,
        status: item.status,
        parameters: item.parameters, // oid, qty, iil
        updated_at: item.updated_at,
        created_at: item.created_at,
      }
    }));
    this.__notifications = [...newNotifications];
  }

  async reloadNotifications(toastData){
    const result = await IaQuestionApi.read.getTaskStatusWorker({});
    await this.__loadNotifications(result.getTaskStatusWorker);
    if (toastData){
      const { workHash, status } = toastData;
      const notification = this.__notifications.find(item => item.data.workHash == workHash);
      if (notification) {
        if(status == WORKER_STATUS.SUCCESS) this.dispatchEvent(new CustomEvent('reload-questions-list', { 
          detail: { ...notification.data.parameters },
          bubbles: true, 
          composed: true 
        }));

        if (status == WORKER_STATUS.FAILURE) 
          IaQuestionApi.write.setWorkerAsSeen({ itw: workHash }); 

        ToastService.showToast({
          type: status == WORKER_STATUS.SUCCESS ? TOAST_TYPES.IA : TOAST_TYPES.IA_ERROR,
          message: notification.name,
          time: 10,
          data: { ...toastData, ...notification.data.parameters }
        })
      }
    }
    this.render();
  }

  toggle(show){
    this.__collapse = (show != undefined) ? !show : !this.__collapse;
    StorageService.set(STORAGE_KEYS.SIDEBAR, { collapse: this.__collapse });
    this.render();
  }

  __startSocket(){
    this.socket.on('connect', () => this.socket.emit("joinRoom", "cms_ai"));
    this.socket.on("message", (socketMessage) => {
      if (socketMessage.data){
        console.log("Socket message: ", socketMessage.data);
        const { status, workerNotify: workHash, message } = socketMessage.data;

        if (status == WORKER_STATUS.SUCCESS || status == WORKER_STATUS.FAILURE) 
          this.reloadNotifications({ workHash, status, message });

        if(status == WORKER_STATUS.SUCCESS) this.dispatchEvent(new CustomEvent(
          'reload-questions-list', 
          { bubbles: true, composed: true }
        ));
      }
    });
    this.socket.on("connect_error", (e) => console.log("error socket connection: ", e));
    this.socket.on("disconnect", () => console.log("disconnect"));
  }

  __logout(){
    const { user, token } = authReducer();

    $api.logout({ pid: user.pid, ses: token },
      (data) => {
        $a.logoutAction();
        navigateTo('/');
      },
      (error) => c_msg('navbar/setActivePage:37', error)
    );
  }

  setConfig(config, permissions = {}) {
    this.__config = config;
    this.__permissions = permissions;
    this.render();
  }

  __clickLink(linkUrl, isActive) {
    if(!isActive) navigateTo(`/${linkUrl}`);
  }

  __backClick() {
    if(this.__config?.customBack){
      this.dispatchEvent(new CustomEvent('backClicked', {
        detail: { work: true }
      }))
    }else{
      history.back();
    }
  }

  __notificationItemClicked(item){
    if (item.type == NOTIFICATION_TYPES.IA){
      const { workHash, status, parameters } = item.data;
      const { questions: qids } = parameters;
      if (status != WORKER_STATUS.SUCCESS) return;

      if (!(qids && qids.length)) 
        return this.__showNotificationError("No se encontraron preguntas", workHash);

      QuestionsApi.read.getQuestionsData({ qidl: qids }, (questions) => {
        if (questions.length == 0)
          return this.__showNotificationError("No se encontraron preguntas", workHash);

        QuestionPreviewModalServices.openModal(questions);
        IaQuestionApi.write.setWorkerAsSeen({ itw: workHash }, () => {
          this.__notifications = this.__notifications.filter(item => item.data.workHash != workHash);
          this.render();
        });
      });
    }
  }

  __showNotificationError(message, workHash){
    const toastData = { type: TOAST_TYPES.WARNING, message, time: 5 };
    ToastService.showToast(toastData);
    IaQuestionApi.write.setWorkerAsSeen({ itw: workHash }, () => {
      this.__notifications = this.__notifications.filter(item => item.data.workHash != workHash);
      this.render();
    });
  }

  renderLink(img, text, linkUrl, isActive, enabled) {
    return html`
      <div @click=${this.__clickLink.bind(this, linkUrl, isActive)}
        class="
          ${ !enabled ? 'hidden' : '' } 
          ${ isActive ? 'active' : '' } 
          sidebar-link px-7 py-3 flex items-center gap-3 relative
        ">
        <div class="triangle absolute left-0"></div>
        <img src="assets/icons/sidebar/${img}.svg" width="20" alt="kb-img" />
        <span class="link-text font-bold text-xs" >${text}</span>
      </div>
    `
  }

  async __clearNotifications(){
    let workerHashes = [];
    let newNotifications = [];
    this.__notifications.forEach(item => {
      const state = item.data.status;
      if(state == WORKER_STATUS.SUCCESS){
        workerHashes.push(item.data.workHash);
      }else{
        newNotifications.push(item);
      }
    });

    workerHashes.forEach(async(workHash) => {
      await IaQuestionApi.write.setWorkerAsSeen({ itw: workHash });
    });
    
    this.__notifications = [...newNotifications];
    this.render();
  }

  __renderNotifications() {
    const notifications = this.__notifications ?? [];
    let notificationsHtml = [];

    notificationsHtml.push(html`
      <div class="navbar-notification-header">
        <img src="assets/icons/sidebar/alert.svg" alt="alert-notification" />
        <span class="navbar-notification-header-text">Contenido para revisar</span>
      </div>
    `);

    if(notifications.length == 0){
      notificationsHtml.push(html`
        <div class="navbar-notification-item">
          <span class="navbar-notification-item-text text-center">
            <b>No hay notificaciones</b>
          </span>
        </div>
      `);
      return notificationsHtml;
    }

    let itemsHtml = [];
    notifications.forEach((item, index) => {
      let notificationText = html`<span class="navbar-notification-item-text">
        Una nueva notificación ha llegado
      </span>`;

      let notificationBtn = html`<img 
        src="assets/icons/sidebar/vista_previa.svg" 
        width="25" 
        alt="preview-notification" />`;

      if (item.type == NOTIFICATION_TYPES.IA){
        const { iil, nm, qty } = item.data.parameters;
        const isFullKb = !Boolean(iil);

        if(item.data.status == WORKER_STATUS.SUCCESS){
          let notText = `${qty} pregunta(s) por párrafo generada(s) de toda la base de conocimiento.`

          if(iil && !isFullKb) {
            const paragraphsQuantity = iil.iids.length;
            notText = `${paragraphsQuantity * qty} pregunta(s) generada(s) de ${paragraphsQuantity} párrafo(s).`
          }

          notificationText = html`<div class="flex flex-col gap-1 justify-center">
            <p class="m-0 navbar-notification-item-text"> ${notText} </p>
            <p class="m-0 navbar-notification-item-text font-semibold">${nm}</p>
          </div>`;
        }

        if (item.data.status == WORKER_STATUS.PENDING) {
          let notText = `Generando ${qty} pregunta(s) por párrafo de toda la base de conocimiento.`
          if (iil && !isFullKb) {
            const paragraphsQuantity = iil.iids.length;
            notText = `Generando ${paragraphsQuantity * qty} pregunta(s) de ${paragraphsQuantity} párrafo(s).`
          }

          notificationText = html`<div class="flex flex-col gap-1 justify-center">
            <p class="m-0 navbar-notification-item-text"> ${notText} </p>
            <p class="m-0 navbar-notification-item-text font-semibold">${nm}</p>
          </div>`;

          notificationBtn = html`<img 
            class="animate-spin"
            src="assets/icons/b/actualizar.svg" 
            width="25" 
            alt="preview-notification" />`;
        }
      }

      itemsHtml.push(html`
        <div @click="${(e) => this.__notificationItemClicked(item)}" 
          class="navbar-notification-item underline-item">
          ${ notificationText }
          ${ notificationBtn }
        </div>
      `)
    });

    notificationsHtml.push(html`
      <div class="w-full flex flex-col overflow-y-auto overflow-x-hidden" style="max-height: 200px;">
        ${ itemsHtml }
      </div>
    `);

    notificationsHtml.push(html`
      <div 
        @click="${ (e) => this.__clearNotifications() }"
        class="cursor-pointer underline paragraph-10 text-left mt-6 mb-1">
        borrar todo
      </div>
    `)

    return notificationsHtml;
  }

  template() {
    const notification = this.__notifications ?? [];

    return /* template */html`
      <div class="flex gap-1 h-screen">
        <div class="
            ${ this.__config?.states && this.__config.states.includes(NAV_STATES.SIDE_BAR) ? '' : 'hidden' }
            ${ this.__collapse ? 'collapse' : '' }
            flex flex-col sidebar shadow-lg h-full
          ">
          <div class="pl-7 pr-3 pt-5 flex items-center sidebar-header">
            <img 
              class="logo"
              src="assets/logo-meludus.svg" 
              alt="logo" 
              width="100"/>
            <img 
              @click=${this.toggle.bind(this, undefined)}
              class="arrow-btn cursor-pointer"
              src="assets/icons/sidebar/menu.svg" 
              alt="arrow">
          </div>

          <div class="flex flex-col w-full mt-5">
            ${ this.renderLink(
              'papiro', 
              'Base conocimiento', 
              "lista_de_base_de_conocimientos",
              this.__config?.module == WEB_MODULES.KNOWLEDGE_BASE,
              this.__permissions?.kbs ?? false
            )}
            ${ this.renderLink(
              'preguntas', 
              'Preguntas', 
              "preguntas",
              this.__config?.module == WEB_MODULES.QUESTIONS,
              this.__permissions?.questions ?? false
            )}
            ${ this.renderLink(
              'programas', 
              'Programas', 
              "lista_de_programas_y_prefabs",
              this.__config?.module == WEB_MODULES.PROGRAMS,
              this.__permissions?.programs ?? false
            )}
          </div>
        </div>

        <main class="w-full relative overflow-x-hidden overflow-y-auto">
          <div class="primary-square absolute"></div>
          <nav class="nav-bar">
            <div @click=${ this.__backClick.bind(this) } class="
                back-btn
                ${ this.__config?.states && this.__config.states.includes(NAV_STATES.BACK) ? '' : 'hidden' }
              ">
              <img 
                class="back-img" 
                src="assets/icons/b/flecha_desplegar.svg" 
                width="30" 
                alt="back-btn" />
              <span class="back-text">volver</span>
            </div>

            <div class="
                nav-buttons
                ${ this.__config?.states && this.__config.states.includes(NAV_STATES.NAV_BAR) ? '' : 'hidden' }
              ">
              <ml-layout-menu-btn 
                data="${JSON.stringify(this.__menuButtons?.help ?? '')}">
              </ml-layout-menu-btn>

              <ml-layout-menu-btn
                notifications="${ notification.length }"
                data="${JSON.stringify(this.__menuButtons?.notifications ?? '')}">
                ${ this.__renderNotifications() }
              </ml-layout-menu-btn>

              <ml-layout-menu-btn
                data="${JSON.stringify(this.__menuButtons?.profile ?? '')}">
                <div class="flex gap-3 items-center px-2">
                  <span @click=${this.__logout.bind(this)}
                    class="font-bold cursor-pointer"
                    style="font-size: 10px;">
                    Cerrar sesión
                  </span>
                  <img width="15" src="assets/icons/sidebar/logout.svg" alt="logout-icon" />
                </div>
              </ml-layout-menu-btn>
            </div>
          </nav>

          <section class="w-full box-border px-5">
            <slot></slot>
          </section>
        </main>
      </div>
    `;
  }
}

window.customElements.define(ComponentTagName, Layout);
