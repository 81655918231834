export const formatGetKbInfo = (json) => {
  let kbud = new Date(Date.parse(json.kbud));
  let lkbud = new Date(Date.parse(json.lkbud));
  let dat = new Date(Date.parse(json.dat));
  let { ent, pub, pubp, url } = json;
  return {
    creation_date: dat.toLocaleString(),
    entry_into_force: ent,
    html: json.html,
    kbt: json.kbt,
    last_updated: kbud.toLocaleString(),
    last_kb_updated: lkbud.toLocaleString(),
    name: json.nm,
    published: pub,
    place_published: pubp,
    short_name: json.sn,
    srt: json.srt,
    status: json.sts,
    source: url,
    user: json.usen,
  }
}

export const formatUpdateKbInfo = (json) => {
  let kbud = new Date(Date.parse(json.kbud));
  return {
    last_updated: kbud.toLocaleString(),
  }
}

