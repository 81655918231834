const create_element = (element, attribute, inner) => {
  if (typeof (element) === "undefined") {
    return false;
  }
  if (typeof (inner) === "undefined") {
    inner = "";
  }
  var el = document.createElement(element);
  if (typeof (attribute) === 'object') {
    for (var key in attribute) {
      el.setAttribute(key, attribute[key]);
    }
  }
  if (!Array.isArray(inner)) {
    inner = [inner];
  }
  for (var k = 0; k < inner.length; k++) {
    if (inner[k].tagName) {
      el.appendChild(inner[k]);
    } else {
      el.appendChild(document.createTextNode(inner[k]));
    }
  }
  return el;
};

const toNode = (string) => {
  const $div = create_element('div');
  $div.innerHTML = string.trim();;
  return $div.firstChild;
}

const encapsulate = (string, cls="") => {
  let $div = create_element('div', { class: cls });
  $div.innerHTML = string.trim();
  return $div;
}

const loadData = (string, data) => {
  Object.keys(data).forEach(key => {
    string = string.replaceAll(`$${key}$`, data[key]);
  })
  return string;
}

export {
  create_element,
  toNode,
  encapsulate,
  loadData,
};
