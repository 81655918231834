import { navbarPrivileges } from "privilege_manager/components";

export class LayoutService {

  static setConfig(config) {
    const layoutComponent = document.querySelector('ml-layout');

    if (layoutComponent) {
      if (config.states.length) return navbarPrivileges.menuPrivileges(
        (permit) => layoutComponent.setConfig(config, permit)
      );
    
      layoutComponent.setConfig(config)
    }
  }

  static updateNotifications(){
    const layoutComponent = document.querySelector('ml-layout');
    if (layoutComponent) layoutComponent.reloadNotifications();
  }
}