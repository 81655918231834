import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { prg_pf } from './../../urls';
import { formatCreatePrPf } from './format';

export const createPrPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prg_pf.createPrPf, data),
    (dataSuccess) => {
      let formatted = formatCreatePrPf(dataSuccess.createPrPf);
      success(formatted);
    },
    (dataError) => error(dataError)
  );
}

