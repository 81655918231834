import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { formatGetKbInfo, formatUpdateKbInfo } from './format';
import { manual_kb as URL } from './../../urls';
import { obj } from './../../urls';
import { getPrivilege } from '../utils';
import Privilege from 'privilege_manager/privileges';
import { alert } from 'components/modals';

export const getItemTypes = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(obj.getItemTypes, data),
    (resp) => success(resp.getItemTypes),
    (dataError) => error(dataError),
    { api: obj.getItemTypes, data }
  );
}

export const getKbInfo = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.getKbInfo, data),
    (resp) => success(formatGetKbInfo(resp.getKbInfo)),
    (dataError) => error(dataError),
    { api: URL.getKbInfo, data }
  )
}

/**
 * Description: only for "standard_kb" privilege
 * */
export const updateKbInfo = (data, success, error) => {
  getPrivilege(Privilege.standard_kb, (resp) => {
    if(resp.permit){
      _updateKbInfo(data, success, error, resp);
    }else{
      alert({
        title: 'Permiso denegado',
        body: `Lo sentimos, no tienes el permiso necesario 
          para ver editar los metadatos de esta base de conocimiento.`,
        btn_ok: 'salir'
      })
    }
  }, data.pid)
}

const _updateKbInfo = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.updateKbInfo, data),
    (resp) => success(formatUpdateKbInfo(resp.updateKbInfo)),
    (errorData) => error(errorData),
    { api: URL.updateKbInfo, data }
  )
}

export const saveKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.saveKb, data),
    (resp) => success(formatUpdateKbInfo(resp.saveKb)),
    (errorData) => error(errorData),
    { api: URL.saveKb, data }
  )
}

export const getKbPreview = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.kbPreview, data),
    (resp) => success(resp.getKbPreview.data),
    (errorData) => error(errorData),
    { api: URL.getKbPreview, data }
  )
}

export const saveHtmlToKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.saveHtmlToKb, data),
    (resp) => success(formatUpdateKbInfo(resp.saveHtmlToKb)),
    (e_data) => error(e_data),
    { api: URL.saveHtmlToKb, data }
  )
}

