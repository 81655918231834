import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { makeQuestions as url } from './../../urls';

export const createAIQuestionsFromKb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.createAIQuestionsFromKb, data),
    (s_data) => { if (success) success(s_data) },
    (s_error) => { if (error) error(s_error) },
    { api: url.createAIQuestionsFromKb, data }
  )
}

export const createAIQuestionsPh = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.createAIQuestionsPh, data),
    (s_data) => { if (success) success(s_data) },
    (s_error) => { if (error) error(s_error) },
    { api: url.createAIQuestionsPh, data }
  )
}

export const setWorkerAsSeen = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.setWorkerAsSeen, data),
    (s_data) => { if (success) success(s_data.setWorkerAsSeen); },
    (e_data) => { if (error) error(e_data); },
    { api: url.setWorkerAsSeen, data }
  )
}
