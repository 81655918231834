import { SAVE_STATE as $s } from 'utils/const';

export const formatPrPfInfo = (data) => {
  let date = new Date(Date.parse(data.lud));
  return { ...data, lud: date.toLocaleString() }
}

export const formatPfList = (list) => {
  return list.map( item => {
    return {
      ...item,
      lpo: item.lpo ? item.lpo : []
    }
  })
}

