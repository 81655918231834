import { orderJsonObject } from 'utils/operators';

export const formatList = (list) => {
  return list.map(item => {
    let date_lud = new Date(Date.parse(item.lud));
    let date_dat = new Date(Date.parse(item.dat));
    let ans = (item.ans.length == 1 && item.ans[0] == null) ? [] : item.ans;
    return {
      ...item,
      dat: date_dat.toLocaleDateString(),
      lud: date_lud.toLocaleString(),
      ans
    }
  })
}

export const formatQuestion = (qu) => {
  let cd = new Date(Date.parse(qu.cd));
  let lud = new Date(Date.parse(qu.lud));
  qu.cd = cd.toLocaleDateString();
  qu.lud = lud.toLocaleString();
  return qu;
}

export const formatSimQu = (list) => {
  return list.sort(orderJsonObject('rnk'));
}

