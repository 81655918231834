import HandleRequest from './../../handle_request';
import { prg_pf as prpf } from './../../urls';
import { users } from './../../urls';
import ApiService from './../../api_service';
import { formatList } from './format';
import { getPrivilege, checkPrivileges } from '../utils';
import Privilege from 'privilege_manager/privileges';
import { alert, dialog } from 'components/modals';

/**
 * Description: only for "programs" privilege
 * */
export const getPrPfList = (data, success, error) => {
  getPrivilege(Privilege.programs, (resp) => {
    if(resp.permit){
      _getPrPfList(data, success, error, resp);
    }else{
      alert({
        title: 'Permiso denegado',
        body: `Lo sentimos, no tienes el permiso necesario 
          para ver la lista de programas y prefag.`,
        btn_ok: 'salir'
      })
    }
  }, data.pid)
}

const _getPrPfList = (data, success, error, permission) => {
  HandleRequest(
    () => ApiService.post(prpf.getPrPfList, data),
    (dataSuccess) => {
      let resp = dataSuccess.getPrPfList;
      let all = permission.attr?.all;
      let list = formatList(resp.list, all ? all : false , data.pid);
      success({ ...resp, list });
    }, (dataError) => error(dataError),
    { api: prpf.getPrPfList, data }
  );
}

/**
 * Description: only for "programs" privilege
 * */
export const getEditorList = (data, success, error) => {
  getPrivilege(Privilege.programs, (resp) => {
    if(resp.permit){
      _getEditorList(data, success, error);
    }else{
      alert({
        title: 'Permiso denegado',
        body: `Lo sentimos, no tienes el permiso necesario 
          para ver la lista de editores.`,
        btn_ok: 'salir'
      })
    }
  }, data.pid)
}

export const _getEditorList = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(users.getEditorList, data),
    (dataSuccess) => { if (success) success(dataSuccess.getEditorList) },
    (dataError) => { if (error) error(dataError) },
    { api: users.getEditorList, data }
  )
}

/**
 * Description: only for "create_programs" privilege
 * For change ownership it must have "change_object_ownership" privilege
 * */
export const deletePrPf = (data, success, error) => {
  let { coo, create_programs } = Privilege;
  checkPrivileges([coo, create_programs], (resp) => {
    let { create_programs, change_object_ownership } = resp;
    let { old_pid, pid } = data;
    if(create_programs.permit && ((old_pid == pid) || change_object_ownership.permit)){
      let no_owner = '<p class="ml_paragraph ml_text-center">Este trabajo no te pertenece.</p>'
      dialog({
        title: '¡Atención!',
        body_html: `<div>
          ${old_pid != pid ? no_owner : ''}
          <p class="ml_paragraph ml_text-center">Estas apunto de remover un trabajo, si esta relacionado con otros trabajos sera removido de ellos igualmente.</p>
          <p class="ml_paragraph ml_text-center">¿Esta seguro de querer continuar?</p>
        </div>`,
        btn_ok: 'continuar',
        ok: () => {
          _deletePrPf(data, success, error);
        }
      })
    }else{
      alert({
        title: 'Permiso denegado',
        body: `Lo sentimos, no tienes el permiso necesario para remover este trabajo.`,
        btn_ok: 'salir'
      })
    }
  }, data.pid)
}

const _deletePrPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.deletePrPf, data),
    (s_data) => success(s_data),
    (e_data) => error(e_data),
    { api: prpf.deletePrPf, data }
  ) 
}

