import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { users as url } from './../../urls';

export const setEditorsToObject = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.setEditorsToObject, data),
    (s_data) => success(s_data.setEditorsToObject),
    (s_error) => error(s_error),
    { api: url.setEditorsToObject, data }
  )
}

