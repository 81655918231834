import * as $u from './../utils.js';
import $p from './../privileges';

export const menuPrivileges = (callback) => {
  $u.getAllPrivileges((resp) => {
    const questions = $u.hasPrivilege(resp, $p.questions),
      programs = $u.hasPrivilege(resp, $p.programs),
      boe_kb = $u.hasPrivilege(resp, $p.boe_kb),
      standard_kb = $u.hasPrivilege(resp, $p.standard_kb);
    callback({
      kbs: boe_kb.permit || standard_kb.permit,
      questions: questions.permit,
      programs: programs.permit,
    });
  });
}
