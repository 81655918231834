import { html } from 'lit-html';
import styles from './badge.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-badge";

class BadgeComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
  }

  onInit() { }

  onDestroy() { }

  static get observedAttributes() {
    return ['color'];
  }

  get color() { return this.getAttribute('color'); }
  set color(value) { this.setAttribute('color', value); }

  template() {
    const color = this.color ?? 'primary';

    return /* template */html`
      <div class="ml-badge ${color}">
        <span></span>
        <slot></slot>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, BadgeComponent);
