export const emailName = (value) => {
  let regex = new RegExp("^[A-Za-z0-9.\-_@]{1,50}$");
  return regex.test(value);
}

export const emailOnly = (value) => {
  let regex = new RegExp("\\S+@\\S+\\.\\S+");
  return regex.test(value);
}

export const onlyLettersAndNumbers = (value) => {
  let has_number = /\d/.test(value);
  let has_letter = /[a-zA-Z]/.test(value);
  let no_spaces = value.indexOf(' ') === -1;
  return has_letter && has_number && no_spaces;
}

export const lawFromUrl = (url) => {
  let regex = new RegExp("^((https\:\/\/)|(www\.)|(https\:\/\/www\.))?(boe\.es\/)");
  return regex.test(url);
};

export const createLawFromUrl = (common_name) => {
  let regex = new RegExp("^[A-Za-z0-9\u00C0-\u00FF ,.()/\-]{1,250}$");
  return regex.test(common_name);
}

export const shortName = (common_name) => {
  let regex = new RegExp("^[A-Za-z0-9\u00C0-\u00FF ,./\-«»<>]{1,}$");
  return regex.test(common_name);
}

export const isValidPrgPfName = (common_name) => {
  let regex = new RegExp("^[A-Za-z0-9\u00C0-\u00FF ,./\-]{1,}$");
  return regex.test(common_name);
}

export const isValidPrgPfNameSearch = (common_name) => {
  let regex = new RegExp("^[A-Za-z0-9\u00C0-\u00FF ,./\-]{0,150}$");
  return regex.test(common_name);
}

export const resolveLawErrorObservation = (observation) => {
  let regex = new RegExp("^[A-Za-z0-9\u00C0-\u00FF\n ,.@]{1,255}$");
  return regex.test(observation);
}

export const searchLawFilter = (common_name) => {
  let regex = new RegExp("^[A-Za-z0-9\u00C0-\u00FF ,./\-]{0,30}$");
  return regex.test(common_name);
}

export const textAndDate = (value) => {
  let regex = new RegExp("^[A-Za-z0-9\u00C0-\u00FF ,.\/\-]{0,50}$");
  return regex.test(value);
}

export const textAndUrl = (value) => {
  let regex = new RegExp("^[A-Za-z0-9\u00C0-\u00FF ,./\?\-\_\:]{0,}$");
  return regex.test(value);
}

export const dateString = (value) => {
  let regex = new RegExp("^(19|20)\\d\\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$");
  return regex.test(value);
}

export const onlyNumbers = (value) => {
  let regex = new RegExp("^[0-9]{1,}$");
  return regex.test(value);
}

