import { toNode } from 'utils/html_creator';
import view from './layout.html';

const dialogForm = (data={}) => {
  let $view = toNode(view);
  Handler($view, data);
  document.querySelector('body').appendChild($view);
}

const Handler = (layout, data) => {

  let title = layout.querySelector('#cmp_dialog_title'),
    body = layout.querySelector('#cmp_dialog_body'),
    btn_cancel = layout.querySelector('#cmp_cancel'),
    btn_ok = layout.querySelector('#cmp_ok');
  
  if(data.cssClass) layout.classList.add(data.cssClass);

  if(data.title){
    let html = `<p class="ml_paragraph ml_text-center"><strong>${data.title}</strong></p>`
    title.innerHTML = html;
  }else{
    if(data.title_html) title.innerHTML = data.title_html;
  }

  if(data.body) body.innerHTML = data.body;
  if(data.btn_cancel) btn_cancel.innerText = data.btn_cancel;
  if(data.btn_ok) btn_ok.innerText = data.btn_ok;
  if(data.eventHandler) data.eventHandler(layout); 
  
  layout.addEventListener('click', (e) => {
    if(e.target.matches('#cmp_dialog_close')){
      layout.remove();
    }
    
    if(e.target === btn_ok){
      e.preventDefault();
      if(data.ok){
        if(data.ok(layout)) layout.remove();
      }else{
        layout.remove();
      }
    }

    if(e.target === btn_cancel){
      if(data.cancel) data.cancel();
      layout.remove();
    }
  });
}

export default dialogForm;

