import { html } from 'lit-html';
import styles from './icon-button.styles.scss';
import WebComponent from 'utils/web-component';

export const ICON_BUTTON_STATES = {
  NORMAL: 'normal',
  DISABLED: 'disabled',
}

const ComponentTagName = "ml-icon-button";

class IconButtonComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
  }

  onInit() { }

  onDestroy() { }

  static get observedAttributes() {
    return ['type', 'color', 'mode',"size"];
  }

  get type() { return this.getAttribute('type'); }
  set type(value) { this.setAttribute('type', value); }

  get color() { return this.getAttribute('color'); }
  set color(value) { this.setAttribute('color', value); }

  get mode() { return this.getAttribute('mode'); }
  set mode(value) { this.setAttribute('mode', value); }

  get size() { return this.getAttribute('size'); }
  set size(value) { this.setAttribute('size', value); }

  __clickHandler(event) {
    this.dispatchEvent(new CustomEvent('btnClick', {
      detail: this,
      bubbles: true,
      composed: true,
    }));
  }

  template() {
    const type = this.type ?? 'button';
    const color = this.color ?? 'primary';
    const size = this.size ?? 'lg';
    const mode = this.mode ?? ICON_BUTTON_STATES.NORMAL;

    return /* template */html`
      <button 
        @click="${(e) => this.__clickHandler()}" 
        class="ml-icon-button ${color} ${mode} ${size}"
        type="${type}">
        <slot class="button-content"></slot>
      </button>
      `;
  }
}

window.customElements.define(ComponentTagName, IconButtonComponent);
