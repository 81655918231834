import axios from 'axios';
import {BASE_URL} from './urls';
import { c_query } from 'utils/console';
import CryptoJS from 'crypto-js';
import { ENV } from 'utils/env';

const singleton = 'SINGLETON';
const singletonEnforcer = 'ENFORCER';

class ApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this.currentRevision = null;
    this.session = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
    });
  }

  buildPCEK = (url, data) => {
    let str = JSON.stringify(data) + ENV.PCEK;
    let crypt = CryptoJS.SHA1(str);
    c_query('PCEK:', {url, str, sha1: CryptoJS.enc.Hex.stringify(crypt) });
    return { headers: { PCEK: CryptoJS.enc.Hex.stringify(crypt) } };
  }

  get = (...params) => this.session.get(...params);
  
  post = (...params) => this.session.post(...params, this.buildPCEK(...params));

  put = (...params) => this.session.put(...params);

  patch = (...params) => this.session.patch(...params);

  delete = (...params) => this.session.delete(...params);

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }
}

export default ApiService.instance;

