export const p_types = {
  MAIN_TITLE: 1,
  BOOK: 2,
  TITLE: 3,
  CHAPTER: 4,
  SECTION: 5,
  ARTICLE: 6,
  PARAGRAPH: 7,
  TITLE_NOTE: 8,
  BODY_NOTE: 9,
  ADD_PRIVISION: 10,
  TRANSITIONAL_PROVISION: 11,
  REPEAL_PROVISION: 12,
  FINAL_PROVISION: 13,
  SUBSECTION: 14,
  PREAMBLE: 15,
  SIGN: 16,
  BLOCK: 17,
  ANNEXED: 18,
  TABLE: 19,
  TEMARY: 20,
  THEME: 21,
  EPIGRAPH: 22,
  SUB_EPIGRAPH: 23,
  PULL_APART: 24,
  SUB_PULL_APART: 25,
  AUTHOR_PARAGRAPH: 26,
  REPEALED: 27,
}

export const STATE = {
  DRAFT: 15, // PINK
  REVISION: 5, // YELLOW
  STAGING: 2, // etiqueta morada; si up false border verder sino amarillo
  PUBLISHED: 0, // GREEN
  EDITION: 10, // etiqueta pink
  REVISION_EDITION: 4, // etiqueta yellow
  DISABLED: 50,
  DEPRECATE: -1,
  AUX: -50,
}

export const i_types = {
  ROOT_PROGRAM: 1,
  TITLE: 2,
  BLOCK: 3,
  THEME: 4,
  BLANK: 6,
  NUMERIC: 5,
}

export const prg_item_type = {
  ROOT_PROGRAM: 250,
  TITLE: 251,
  BLOCK: 252,
  THEME: 253,
  BLANK: 254,
  NUMERIC: 255,
}

export const NAV_STATES = {
  BACK: 'BACK',
  NAV_BAR: 'NAV_BAR',
  SIDE_BAR: 'SIDE_BAR',
}

export const PRG_PF_TYPE = {
  PROGRAM: 'prg',
  PREFAB: 'pf',
  KB: 'kb'
}

export const PARSE_TYPE = {
  LAW: 'boe_src',
  KB: 'temario_ma'
}

export const SAVE_STATE = {
  SAVED: 1,
  NO_SAVED: 2,
  ERROR: 3,
}

export const CHAR_KEYS = {
  ONE: '¤',
}

export const treeItem = {
  id: 'ii',
  cnt: 'ic',
  prt: 'ip',
  pos: 'ipo',
  typ: 'it',
  ntyp: 'int',
	stop: 'iv',
	demo: 'id',
	sts: 'is', // status
	oid: 'io',
	bread: 'ib',
	pxy_rid: 'ix', // proxy root id
	pxy_id: 'xi',
	pxy_nm: 'xn',
	pxy_sts: 'xs',
	pxy_cls: 'xc',
  il: 'il', // Only on getLaw endpoint - Item level
  al: 'al', // Only on getLaw endpoint - Alert
	children: 'h',
  // add on front
  state: 'state', // <- For check, uncheck and partial.
  stat: 'stat', // <- node's statistics.
  level: 'il',
  path: 'ipa',
  prefix: 'ipr',
}

export const WEB_MODULES = {
  KNOWLEDGE_BASE: 'knowledgeBase',
  QUESTIONS: 'questions',
  PROGRAMS: 'programs',
  NONE: 'none',
}

export const WORKER_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
}
