
export const STORAGE_KEYS = {
  SIDEBAR: "sidebar",
}

export class StorageService {

  static set = (key, data) => {
    if(key && data){
      const dataStorage = JSON.parse(localStorage.getItem(key));
      if(dataStorage) data = { ...dataStorage, ...data };
      localStorage.setItem(key, JSON.stringify(data));
    }else{
      console.error('Storage.set: key and data are required');
    }
  }

  static get = (key) => {
    return JSON.parse(localStorage.getItem(key));
  }
}
