import { toNode } from 'utils/html_creator';
import { p_types as $t, treeItem as $it } from 'utils/const';
import { STATE } from './const';

export const countAlerts = (node) => {
  let quantity = node[$it.al] ? 1 : 0;
  node[$it.children].forEach((child) => {
    quantity = quantity + countAlerts(child);
  });
  return quantity;
};

export const findNode = (id, node) => {
  if (node[$it.id] === id) return node;
  for (var i = 0, child; child = node[$it.children][i]; ++i) {
    let node_found = findNode(id, child);
    if (node_found) return node_found;
  }
  return null
};

export const sortChildren = (children) => {
  children.forEach((child, index) => {
    child[$it.pos] = index;
  });
};

export const pushRelevantData = (changes, ...nodes) => {
  nodes.forEach(node =>{
    changes.push({
      iid: node[$it.id],
      ptd: node[$it.prt],
      alert: node[$it.al],
      pos: node[$it.pos],
      type: node[$it.typ],
      lvl: node[$it.il],
    });
  });
}

export const removeNode = (children, position) => {
  let array_node = children.splice(position, 1);
  sortChildren(children);
  return array_node[0];
};

export const addNode = (children, node, position) => {
  children.splice(position, 0, node);
  sortChildren(children);
};

export const updateChildrenLevel = (children, level) => {
  children.forEach(child => {
    child[$it.il] = level;
    updateChildrenLevel(child[$it.children], child[$it.il] + 1);
  });
};

export const removeSegment = (children, initial_position, end_position) => {
  let segment = children.splice(initial_position, end_position);
  sortChildren(children);
  return segment;
};

export const updateParentOfChildren = (children, parent) => {
  children.forEach(child =>{
    child[$it.prt] = parent;
  });
};

export const prepareChildren = (children, parent, new_level) => {
  children.forEach(child => {
    child[$it.prt] = parent;
    child[$it.il] = new_level;
  });
}

const mergeArray = (base, array) => {
  array.forEach(item => {
    const exist = base.some((node) => node.iid === item.iid);
    if(!exist){
      base.push({ ...item });
    }
  });
}

const turnToFalse = (nodes) => {
  let response = nodes.map(node => {
    if(node.alert) node.alert = false;
    return node;
  });
  return response;
}

export const buildNodeChangesForAlerts = (stack, json) => {
  let alerts = buildNodeWithoutChanges(json);
  if(stack.length > 1){
    let resp = stack.pop();
    let reverse = stack.slice().reverse();
    mergeArray(resp, alerts); 
    reverse.forEach(nodes => {
      mergeArray(resp, nodes); 
    });
    turnToFalse(resp);
    return resp;
  }
  let response = turnToFalse(stack.pop());
  mergeArray(response, alerts);
  return response;
}

const findAlertNodes = (root, stack) => {
  if (root[$it.al]) return pushRelevantData(stack, root);
  for (var i = 0, child; child = root[$it.children][i]; ++i) {
    findAlertNodes(child, stack);
  }
};

export const buildNodeWithoutChanges = (json) => {
  let alerts = [];
  findAlertNodes(json, alerts);
  return turnToFalse(alerts);
}

export const prepareReferenceText = (html) => {
  let node = toNode(html);
  let marcadores = node.querySelector('.marcadores');
  marcadores?.remove();
  node.querySelectorAll('p.bloque').forEach(p => {
    if(p.textContent.includes('[Bloque')){
      p.remove();
    }
  });
  return node;
}

export const getClassByNodeLevel = (level) => {
  return `law-ml-${level}`
}

export const getClassByNodeType = (item_type) => {
  switch (item_type) {
    case $t.MAIN_TITLE: return 'law_main_title';
    case $t.BOOK: return 'law_book';
    case $t.TITLE: return 'law_title';
    case $t.CHAPTER: return 'law_chapter';
    case $t.SECTION: return 'law_section';
    case $t.ARTICLE: return 'law_article';
    case $t.PARAGRAPH: return 'law_paragraph';
    case $t.TITLE_NOTE: return 'law_title_note';
    case $t.BODY_NOTE: return 'law_body_note';
    case $t.ADD_PRIVISION: return 'law_add_privision';
    case $t.TRANSITIONAL_PROVISION: return 'law_transitional_provision';
    case $t.REPEAL_PROVISION: return 'law_repeal_provision';
    case $t.FINAL_PROVISION: return 'law_final_provision';
    case $t.SUBSECTION: return 'law_subsection';
    case $t.PREAMBLE: return 'law_preamble';
    case $t.SIGN: return 'law_sign';
    case $t.BLOCK: return 'law_block';
    case $t.ANNEXED: return 'law_annexed';
    case $t.TABLE: return 'law_table';
    case $t.TEMARY: return 'law_temary';
    case $t.THEME: return 'law_theme';
    case $t.EPIGRAPH: return 'law_epigraph';
    case $t.SUB_EPIGRAPH: return 'law_sub_epigraph';
    case $t.PULL_APART: return 'law_pull_apart';
    case $t.SUB_PULL_APART: return 'law_sub_pull_apart';
    case $t.AUTHOR_PARAGRAPH: return 'law_author_paragraph';
    case $t.REPEALED: return 'law_repealed';
    default: return 'no_class_for_this'
  }
}

export const getKbStatus = (id) => {
  let status = 'Borrador';
  if(id === 2) status = "Revision";
  if(id === 3) status = "Publicado";
  return status;
}

export const showOrRemove = (flag, elements) => {
  const is_array = Array.isArray(elements);
  if(is_array){
    elements.forEach(e => {
      if(flag){
        e.classList.remove('hidden');
      }else{
        e.remove();
      }
    });
  }else{
    if(flag){
      elements.classList.remove('hidden');
    }else{
      elements.remove();
    }
  }
}

export const cloneJson = (json) => JSON.parse(JSON.stringify(json));

export const pushOrReplaceInStack = (node, stack) => {
  let exist = stack.some((n) => n[$it.id] === node[$it.id]);
  if(exist){
    for (var i = 0, e; e = stack[i]; ++i) {
      if(e.item_id === node[$it.id]){
        stack[i] = { ...node }
        return 0;
      }
    }
  }else{
    stack.push({ ...node });
  }
}

export const pushReplaceOrRemove = (node, json, stack) => {
  let original_node = findNode(node[$it.id], json);
  const has_changes = JSON.stringify(node) !== JSON.stringify(original_node);
  if(has_changes){
    pushOrReplaceInStack(node, stack);
  }else{
    const exist = stack.some((n) => n.item_id === node[$it.id]);
    if(exist){
      for (var i = 0, e; e = stack[i]; ++i) {
        if(e.item_id === node[$it.id]){
          stack.splice(i, 1);
          return 0;
        }
      }
    }
  }
}

export const countNoAlertNodes = (stack) => {
  let counter = 0;
  stack.forEach(e => {
    if(!e.is_alert) counter++ ;
  })
  return counter;
}

export const buildDescription = (info, stack) => {
  let text = `Descripción por parte del usuario: \n ${info} \n \n Nodos alterados: \n`;
  stack.forEach(e => {
    if(!e.is_alert) {
      text = `${text} \n ${JSON.stringify(e)} \n`;
    } 
  })
  return text;
}

export const getStateName = (id) => {
  let name = "Borrador";
  if(id === STATE.REVISION || id === STATE.REVISION_EDITION) name = "En revisión";
  if(id === STATE.PUBLISHED) name = "Publicado";
  if(id === STATE.STAGING) name = "Con dependencias";
  if(id === STATE.EDITION) name = "En edición";
  return name;
}

export const getStateNameForQuestion = (id) => {
  let name = "Borrador";
  if (id === STATE.REVISION || id === STATE.REVISION_EDITION) name = "En revisión";
  if (id === STATE.PUBLISHED) name = "Publicada";
  if (id === STATE.STAGING) name = "Aprobada";
  if (id === STATE.EDITION) name = "En edición";
  return name;
}

export const getStateBkClass = (id) => {
  let name = "ml_bk-pink";
  if(id === STATE.REVISION || id === STATE.REVISION_EDITION) name = "ml_bk-yellow";
  if(id === STATE.PUBLISHED) name = "ml_bk-green";
  if(id === STATE.STAGING) name = "ml_bk-violet";
  return name;
}

export const checkContentForTable = (cnt) => {
  if(cnt.includes('</table>')){
    return toNode(cnt);
  }
  return cnt;
}

const wordPathKb = (words, dots, text) => {
  if(words.length === 1){
    return words[0];
  }else{
    return dots ? `${words[0]} ${words[1]}...}` : text;
  }
}

export const buildKbPath = (string, spc = '/', dots=false) => {
  let main_array = string.split('||');
  let obj_array = main_array.shift().split('§');
  let path = "";
  let nodes = [];
  main_array.forEach((node, index) => {
  	let text_array = node.split('§');
    let words = text_array[1].split(' ');
    nodes.push({ id: parseInt(text_array[0]), cnt: text_array[1]});
    if(index > 0){
    	path = path + ` ${spc} ${wordPathKb(words, dots, text_array[1])}`;
    }else{
    	path = wordPathKb(words, dots, text_array[1]);
    }
  })
  let data = {
  	obj: {
    	id: parseInt(obj_array[0]),
      cnt: obj_array[1]
    },
    nodes
  }
  return {title: obj_array[1], path, data};
}

export const setEndOfContentEditable = (contentEditableElement) => {
  let range,selection;
  if(document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
  {
    range = document.createRange();//Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
    range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection();//get the selection object (allows you to change selection)
    selection.removeAllRanges();//remove any selections already made
    selection.addRange(range);//make the range you have just created the visible selection
  }
  else if(document.selection)//IE 8 and lower
  { 
    range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
    range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
    range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
    range.select();//Select the range (make it the visible selection
  }
}

export const addIfNotEmpty = (input, data, key) => {
  let value = input.value.trim();
  if(value.length > 0) data[key] = value;
}

export const intToStr = (nro) => nro < 10 ? `0${nro}` : nro;

export const getSelectionText = () => {
  if(window.getSelection){
    return window.getSelection().toString();
  }
  if(document.getSelection){
    return document.getSelection().toString();
  }
  if(document.selection){
    return document.selection.createRange().text;
  }
  return "";
}

export const orderJsonObject = (field) => {
  return function(a, b) {    
    if (a[field] > b[field]) {    
      return 1;    
    } else if (a[field] < b[field]) {    
      return -1;    
    }    
    return 0;    
  }   
}

