import { html } from 'lit-html';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import styles from './confirmation-msg.styles.scss';
import WebComponent from 'utils/web-component';
import "components/atom/button/button.component";
import "components/atom/common-input/common-input.component";

const ComponentTagName = "preview-confirmation-msg";
const defaultData = {
  title: "",
  htmlContent: "",
  inputText: "",
  okText: "",
  onOk: () => {},
  cancelText: "No, volver a vista previa",
  onCancel: () => {}
}

class PreviewConfirmationComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__data = defaultData;
  }

  onInit() { 
    this.root.querySelector('ml-common-input').addEventListener('inputChange', (event) => {
      const match = event.detail.length > 0 && event.detail == this.__data.inputText;
      const confirmBtn = this.root.querySelector('#confirm');
      const input = this.root.querySelector('ml-common-input');

      if(confirmBtn) confirmBtn.mode = match ? "normal" : "disabled";
      if(input) input.setError(match ? "" : "El texto no coincide");
    });

    this.root.addEventListener('btnClick', (event) => {
      const input = this.root.querySelector('ml-common-input');
      if(event.detail.id == "confirm") {
        if(input) input.value = "";
        this.__data.onOk();
      }
      if(event.detail.id == "cancel") {
        if (input) input.value = "";
        this.__data.onCancel();
      }
    });
  }

  onDestroy() { }

  static get observedAttributes() {
    return [];
  }

  loadData(data){
    this.__data = { ...defaultData, ...data };
    this.render();
  }

  template() {
    const data = this.__data ?? defaultData;
    const inputClass = data.inputText.length > 0 ? "" : "hidden";
    const confirmBtnMode = data.inputText.length > 0 ? "disabled" : "normal";
    
    return /* template */html`
      <div class="preview-confirmation-msg">
        <h2>${data.title}</h2>
        <div class="text-block">
          ${ unsafeHTML(data.htmlContent) }
        </div>

        <ml-common-input 
          type="text"
          class="${inputClass} mt-5 mb-10"
          placeholder="${data.inputText}">
        </ml-common-input>

        <div class="flex flex-row gap-4 items-center">
          <ml-button-v2 id="confirm" color="primary" mode="${confirmBtnMode}">
            ${data.okText}
          </ml-button-v2>
          <ml-button-v2 id="cancel" color="primary-outline"> 
            ${data.cancelText}
          </ml-button-v2>
        </div>
      </div>  
      `;
  }
}

window.customElements.define(ComponentTagName, PreviewConfirmationComponent);
