import { AUTH } from './../type';

export const loginDispatch = (data) => {
  return {
    type: AUTH.LOGIN,
    payload: {
      login_data: data.login_data.data,
      privileges: data.privileges,
    }
  }
}

export const logoutDispatch = () => {
  return {
    type: AUTH.LOGOUT,
    payload: { }
  }
}

export const updatePrivilegeDispatch = (data) => {
  return {
    type: AUTH.UPDATE_PRIVILEGES,
    payload: {
      privileges: data.getPrivileges,
    }
  }
}

export const rememberMeDispatch = (data) => {
  return {
    type: AUTH.REMEMBER_ME,
    payload: {
      remember_me: data
    }
  }
}
