import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { makeQuestions as url } from './../../urls';

export const getTaskStatusWorker = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.getTaskStatusWorker, data),
    (s_data) => { if (success) success(s_data.getTaskStatusWorker); },
    (e_data) => { if (error) error(e_data); },
    { api: url.getTaskStatusWorker, data }
  )
}
