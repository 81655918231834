import { ENV } from "./env";

export const c_query = (title, data) => {
  if(ENV.DEBUGGER){
    console.log(` %c ${title} `, "color: #8070e8; font-weight: bold, font-size: 15px", data);
  }
}

export const c_ok = (title, data={}) => {
  if(ENV.DEBUGGER){
    console.log(` %c ${title} `, "color: #38e7ed; font-weight: bold, font-size: 15px", data);
    console.log('%c----o----o----o----o----o----o----o----o----o----o----', 'margin: 10px 0px; font-weight: bold; font-size:16px')
  }
}

export const c_error = (title, data={}) => {
  if(ENV.DEBUGGER){
    console.trace(` %c ${title} `, "color: #ed4add; font-weight: bold, font-size: 15px", data);
    console.log('%c----o----o----o----o----o----o----o----o----o----o----', 'margin: 10px 0px; font-weight: bold; font-size:16px')
  }
}

export const c_msg = (title, data={}) => {
  if(ENV.DEBUGGER){
    console.log(' %c ┌───────[FRONTEND]', 'color: #e8ed27; font-weight: bold');
    console.log(` %c │ ${title}`, 'color: #e8ed27; font-weight: bold', data);
    console.log(' %c └───────', 'color: #e8ed27; font-weight: bold');
  }
}

