import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { obj } from './../../urls';

export const getObject = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(obj.getObject, data),
    (resp) => { if(success) success(resp.getObject) },
    (e) => { if(error) error(e) },
    { api: obj.getObject, data }
  )
}

export const getBreadcrumb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(obj.getBreadcrumb, data),
    (resp) => { if(success) success(resp.getBreadcrumb) },
    (e) => { if(error) error(e) },
    { api: obj.getBreadcrumb, data }
  )
}

export const getNameList = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(obj.getNameList, data),
    (resp) => { if (success) success(resp.getNameList) },
    (e) => { if (error) error(e) },
    { api: obj.getNameList, data }
  )
}
