import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { account } from './../../urls';
import { loginActions as $a } from 'storage/actions';

const APP = "682";

export const login = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(account.login, _addAppId(data)),
    (s_data) => {
      let { person_id, session_token } = s_data.login_data.data;
      let data_to_send = {
        pid: `${person_id}`,
        ses: session_token,
        data: {
          remember_me: data.rm,
        }
      }
      $a.loginAction(s_data);
      sessionStorage.setItem('remember_me', true);
      sessionSetData(data_to_send,
        (resp) => {
          if (resp.data.result === 'ok') {
            $a.rememberMeAction(data.rm);
            success({ user: s_data.login_data.data, privileges: s_data.privileges });
          }
        },
        (e_dataIn) => error(e_dataIn),
      );
    },
    (dataError) => error(dataError),
    { api: account.login, data: _addAppId(data) }
  );
}

export const logout = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(account.logout, _addAppId(data)),
    (dataSuccess) => {
      let status = dataSuccess.logout;
      if(status === 'ok'){
        $a.logoutAction(dataSuccess);
        success(status);
      }else{
        success('error');
      }
    },
    (dataError) => error(dataError),
    { api: account.logout, data: _addAppId(data) }
  );
}

export const forgotPassword = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(account.forgotPassword, _addAppId(data)),
    (dataSuccess) => {
      let { e_email } = dataSuccess.login_forgot_password_data;
      success({ email: e_email });
    },
    (dataError) => error(dataError),
    { api: account.forgotPassword, data: _addAppId(data) }
  );
}

export const userSetAttributes = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(account.userSetAttributes, _addAppId(data)),
    (dataSuccess) => success({ result: dataSuccess.usr_set_attributes_data }),
    (dataError) => error(dataError),
    { api: account.userSetAttributes, data: _addAppId(data) }
  );
}

export const checkPrivilege = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(account.checkPrivilege, _addAppId(data)),
    (dataSuccess) => {
      let { _attributes, _result } = dataSuccess.checkPrivilege;
      success({ attributes: _attributes, result: _result});
    }, (dataError) => error(dataError),
    { api: account.checkPrivilege, data: _addAppId(data) }
  )
}

export const getPrivileges = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(account.getPrivileges, _addAppId(data)),
    (successData) => {
      $a.updatePrivileges(successData);
      success({ privileges: successData.getPrivileges })
    }, (errorData) => error(errorData),
    { api: account.getPrivileges, data: _addAppId(data) }
  );
}

export const sessionSetData = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(account.sessionSetData, _addAppId(data)),
    (successData) => success({ data: successData }),
    (errorData) => error(errorData),
    { api: account.sessionSetData, data: _addAppId(data) }
  );
}

export const sessionGetData = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(account.sessionGetData, _addAppId(data)),
    (s_data) => {
      //let str = s_data.session_get_data.sdata[0].toLowerCase();
      //let json = JSON.parse(str.replace(/'/g, "\""));
      let json = s_data.session_get_data.sdata;
      success(json);
    },
    (errorData) => error(errorData),
    { api: account.sessionGetData, data: _addAppId(data) }
  );
}

export const checkStatus = (data, success, error) => {
  HandleRequest(
    () => ApiService.get(account.status, _addAppId(data)),
    (s_data) => { success(s_data) },
    (errorData) => error(errorData),
    { api: account.status, data: _addAppId(data) }
  );
}

const _addAppId = (data) => {
  return { ...data, app: parseInt(APP) }
}

