import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { formatKbList, formatGetKb } from './format';
import { knowledge as URL } from './../../urls';
import Privilege from 'privilege_manager/privileges';
import { alert, dialog } from 'components/modals';
import { checkPrivileges } from '../utils';

export const getKbList = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.getKbList, data),
    (dataSuccess) => {
      let list = formatKbList(dataSuccess.getKbList);
      success({ list, total: dataSuccess.tr });
    }, (dataError) => { if(error) error(dataError) },
    { api: URL.getKbList, data }
  );
}

export const deleteKb = (data, success, error) => {
  let { boe_kb, standard_kb } = Privilege;
  checkPrivileges([boe_kb, standard_kb], (resp) => {
    let { boe_kb, standard_kb } = resp;
    let { owner_pid, pid } = data;
    if(owner_pid == pid || boe_kb.attr.publish || standard_kb.attr.publish){
      let no_owner = '<p class="ml_paragraph ml_text-center">Este trabajo no te pertenece.</p>'
      dialog({
        title: '¡Atención!',
        body_html: `<div>
          ${owner_pid != pid ? no_owner : ''}
          <p class="ml_paragraph ml_text-center">Estas apunto de remover una base de conocimiento.</p>
          <p class="ml_paragraph ml_text-center">¿Esta seguro de querer continuar?</p>
        </div>`,
        btn_ok: 'continuar',
        ok: () => {
          _deleteKb(data, success, error);
        }
      })
    }else{
      alert({
        title: 'Permiso denegado',
        body: `Lo sentimos, no tienes el permiso necesario para remover esta base de conocimiento.`,
        btn_ok: 'salir'
      })
    }
  }, data.pid)
}


export const deleteAuxKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.deleteKb, data),
    (resp) => success(resp),
    (errorData) => error(errorData),
    { api: URL.deleteKb, data }
  )
}

const _deleteKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.deleteKb, data),
    (resp) => success(resp),
    (errorData) => error(errorData),
    { api: URL.deleteKb, data }
  )
}

export const getKbSourceList = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(URL.getKbSourceList, data),
    (resp) => success(resp.getKbSourceList),
    (errorData) => error(errorData),
    { api: URL.getKbSourceList, data }
  )
}

export const getKbTypeList = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(URL.getKbTypeList, data),
    (resp) => { if (success) success(resp.getKbTypeList); },
    (errorData) => { if (error) error(errorData); },
    { api: URL.getKbTypeList, data }
  )
}
