import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { checkPrivileges } from '../utils';
import Privilege from 'privilege_manager/privileges';
import { alert } from 'components/modals';
import { questions as url } from './../../urls';
import { formatList, formatQuestion, formatSimQu } from './format';

/**
 * Description: only for "questions" privilege
 * */
export const getQuestionList = (data, success, error) => {
  let { coo, questions } = Privilege;
  checkPrivileges([coo, questions], (resp) => {
    if(resp.questions.permit){
      _getQuestionList(data, success, error, resp);
    }else{
      alert({
        title: 'Permiso denegado',
        body: `Lo sentimos, no tienes el permiso necesario 
          para ver la lista de preguntas.`,
        btn_ok: 'salir'
      })
    }
  }, data.pid)
}

const _getQuestionList = (data, success, error, permission) => {
  let { questions, change_object_ownership } = permission;
  HandleRequest(
    () => ApiService.post(url.getQuestionList, data),
    (dataSuccess) => {
      let resp = dataSuccess.getQuestionList;
      let list = formatList(resp.list);
      success({
        ...resp,
        list,
        coo: change_object_ownership,
        can_publish: questions.attr.publish
      });
    }, (dataError) => error(dataError),
    { api: url.getQuestionList, data }
  );
}

export const getKbList = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.getKbList, data),
    (s_data) => { if(success) success(s_data.getKbList) },
    (e_data) => { if(error) error(e_data) },
    { api: url.getKbList, data }
  );
}

export const statsQsFromKbLeafs = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.statsQsFromKbLeafs, data),
    (s_data) => { if (success) success(s_data.statsQsFromKbLeafs); },
    (e_data) => { if (error) error(e_data); },
    { api: url.statsQsFromKbLeafs, data }
  );
}

export const getQsTypeList = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.getQsTypeList, data),
    (s_data) => { if (success) success(s_data.getQsTypeList); },
    (e_data) => { if (error) error(e_data); },
    { api: url.getQsTypeList, data }
  )
}

export const getQsFromKbLeaf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.getQsFromKbLeaf, data),
    (s_data) => success(s_data.getQsFromKbLeaf),
    (e_data) => error(e_data),
    { api: url.getQsFromKbLeaf, data }
  )
}

export const getQuestion = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.getQuestion, data),
    (s_data) => { if (success) success(formatQuestion(s_data.getQuestion)); },
    (e_data) => { if (error) error(e_data); },
    { api: url.getQuestion, data }
  )
}

export const impGetElementList = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.impGetElementList, data),
    (s_data) => success(s_data.impGetElementList),
    (e_data) => error(e_data),
    { api: url.impGetElementList, data }
  )
}

export const impGetQuestionList = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.impGetQuestionList, data),
    (s_data) => success(s_data.impGetQuestionList),
    (e_data) => error(e_data),
    { api: url.impGetQuestionList, data }
  )
}

export const impGetSimilarQs = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.impGetSimilarQs, data),
    (s_data) => success(formatSimQu(s_data.impGetSimilarQs)),
    (e_data) => error(e_data),
    { api: url.impGetSimilarQs, data }
  )
}

export const getTaskStatusWorker = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.getTaskStatusWorker, data),
    (s_data) => { if (success) success(s_data.getTaskStatusWorker); },
    (e_data) => { if (error) error(e_data); },
    { api: url.getTaskStatusWorker, data }
  )
}

export const getQuestionsData = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.getQuestionsData, data),
    (s_data) => { if (success) success(s_data.getQuestionsData); },
    (e_data) => { if (error) error(e_data); },
    { api: url.getQuestionsData, data }
  )
}
