import { html } from 'lit-html';
import styles from './common-input.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-common-input";

class CommonInputComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__errorMsg = "";
  }

  onInit() { }

  __inputChangeHandler(event){
    if (event.target.validity.valid) {
      this.value = event.target.value;
    }else{
      event.target.value = this.value;
    }
    
    this.dispatchEvent(new CustomEvent('inputChange', {
      detail: this.value,
      bubbles: true,
      composed: true,
    }));
  }

  onDestroy() { }

  static get observedAttributes() {
    return [
      'type', 
      'placeholder', 
      'value', 
      'name', 
      'maxLength', 
      'minLength',
      'max',
      'min'
    ];
  }

  get type() { return this.getAttribute('type'); }
  set type(value) { this.setAttribute('type', value); }

  get name() { return this.getAttribute('name'); }
  set name(value) { this.setAttribute('name', value); }

  get placeholder() { return this.getAttribute('placeholder'); }
  set placeholder(value) { this.setAttribute('placeholder', value); }

  get maxLength() { return this.getAttribute('maxLength'); }
  set maxLength(value) { this.setAttribute('maxLength', value); }

  get minLength() { return this.getAttribute('minLength'); }
  set minLength(value) { this.setAttribute('minLength', value); }

  get value() { return this.getAttribute('value'); }
  set value(value) { 
    this.setAttribute('value', value); 
    this.root.querySelector('input').value = value;
    this.__errorMsg = "";
  }

  get max() { return this.getAttribute('max'); }
  set max(value) { this.setAttribute('max', value); }

  get min() { return this.getAttribute('min'); }
  set min(value) { this.setAttribute('min', value); }

  setError(errorMsg){
    this.__errorMsg = errorMsg;
    this.render();
  }


  template() {
    const errorMsg = this.__errorMsg ?? "";
    const showError = errorMsg.length > 0 ? "" : "hidden";
    const inputClass = errorMsg.length > 0 ? "error" : "";

    return /* template */html`
      <div class="ml-common-input">
        <div class="input-wrap ${inputClass}">
          <slot name="left-input"></slot>
          <input
            class="w-full"
            @input="${(e) => this.__inputChangeHandler(e)}"
            placeholder="${this.placeholder}"
            type="${this.type}"
            value="${this.value ?? ""}"
            name="${this.name}"
            maxlength="${this.maxLength}"
            minlength="${this.minLength}"
            max="${this.max}"
            min="${this.min}"/>
          <slot name="right-input"></slot>
        </div>
        <span class="${showError}">${errorMsg}</span>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, CommonInputComponent);
