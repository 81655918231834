import { alert } from 'components/modals';

export const GetLawFromUrl = (json) => {
  return {
    name: json.nm,
    permalink: json.urlp,
    url: json.url,
    published: json.pud,
    entry_into_force: json.ent,
    last_law_update: json.llud
  }
}

export const createLawFromUrl = (json) => {
  return {
    law_id: json.lid,
    alerts: json.alt,
    law_name: json.nm,
    short_name: json.sn,
    law_state: json.sts,
    creation_date: json.cdt,
  }
}

export const genericErrorRequestMsg = (source, data) => {
  let type = source === 'from_api' ? 'Servidor' : 'WEB';
  let msg = 'Intente mas tarde por favor.';

  alert({
    title: 'Fallo la operación',
    body_html: `<div>
        <p class="ml_paragraph ml_text-center">Hubo un error al realizar la operación.</p>
        <p class="ml_paragraph ml_text-center">${msg}</p>
        <p class="ml_paragraph ml_text-center">Origen: <strong>${type}</strong></p>
    </div>`
  })
  return { msg: 'Fallo la operación', source }
}

