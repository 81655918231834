import { html } from 'lit-html';
import styles from './text-hover.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-text-hover";

class TextHoverSwitch extends WebComponent {

   constructor() {
      super(styles, true, ComponentTagName);
    }

   onInit() { }

   onDestroy() { }

   static get observedAttributes() {
      return [ "text", "position", "type", "font"];
   }

   get text() { return this.getAttribute('text'); }
   set text(value) { this.setAttribute('text', value); }

   get position() { return this.getAttribute('position'); }
   set position(value) { 
      value = ["top", "bottom"].includes(value) ? value : "top";
      this.setAttribute('position', value); 
   }

   get type() { return this.getAttribute('type'); }
   set type(value) { 
      value = ["default", "title"].includes(value) ? value : "default";
      this.setAttribute('type', value); 
   }

   get font() { return this.getAttribute('font'); }
   set font(value) { 
      value = ["default", "bold", "semibold", "bolder"].includes(value) ? value : "default";
      this.setAttribute('font', value); 
   }  

   template() {
      const text = this.text ?? "";
      const type = this.type ?? "default";
      const font = this.font ?? "default";
      const position = this.position ?? "top";

      return /* template */html`
      <div class="ml-text-hover">
         <div class="text ${type} ${font}">${text}</div>
         <span class="${position}">${text}</span>
      </div>
      `;
    }
}

window.customElements.define(ComponentTagName, TextHoverSwitch);
