import { html } from 'lit-html';
import styles from './page-loading.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-page-loading";

class PageLoading extends WebComponent {

  constructor() {
    super(styles, false, ComponentTagName);
  }

  onInit() {
    this.__hide = true;
  }

  onDestroy() { }

  static get observedAttributes() {
    return ['text'];
  }

  get text() { return this.getAttribute('text') }
  set text(value) { this.setAttribute('text', value) }

  toggle = (show) => {
    this.__hide = (show != undefined) ? !show : !this.__hide;
    this.render();
  }

  template() {
    return /* template */html`
      <div class="
          ${this.__hide ? 'hidden' : ''}
          absolute top-0 left-0 w-full h-full 
          flex flex-col items-center justify-center bg-white
          z-[1000]
        ">
        <img 
          src="https://blog.meludus.com/wp-content/uploads/img-meludus/statics/guidelines/gif/coach/cargando.gif" 
          width="150"
          alt="loading-gif" />
        <h1>cargando</h1>
      </div>
    `;
  }
}

window.customElements.define(ComponentTagName, PageLoading);
