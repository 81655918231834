import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { users as url } from './../../urls';

export const getEditorListFromObject = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.getEditorListFromObject, data),
    (s_data) => { if (success) success(s_data.getEditorListFromObject) },
    (s_error) => { if(error) error(s_error) },
    { api: url.getEditorListFromObject, data }
  )
}

export const getEditorList = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.getEditorList, data),
    (dataSuccess) => { if(success) success(dataSuccess.getEditorList) },
    (dataError) => { if(error) error(dataError) },
    { api: url.getEditorList, data }
  )
}

