import { alert } from 'components/modals';

const FOR_USER = 'for_user';
const EXCEPTION = 'exception';

const _printAlert = (code, msg, type) => {
  let body_html = `<div>
        <p class="ml_paragraph ml_text-center">Hubo un error al realizar la operación.</p>
        <p class="ml_paragraph ml_text-center">${msg}</p>
        <p class="ml_paragraph ml_text-center">Código: <strong>${code}</strong></p>
      </div>`
  if(type === FOR_USER){
    body_html = `<div>
        <p class="ml_paragraph ml_text-center">Hubo un error al realizar la operación.</p>
        <p class="ml_paragraph ml_text-center">${msg}</p>
      </div>`
  }
  alert({
    title: 'Fallo la operación',
    body_html
  })
}

const codeInterpreter = (data = {}, query) => {
  let code = data.data.result;
  let { user_msg, type_msg } = data.data;

  if(code === 'invs' || code === 'exps'){
    localStorage.removeItem('AUTH');
    sessionStorage.removeItem('page-state');
    location.reload();
    return;
  }

  if(type_msg !== EXCEPTION) _printAlert(code, user_msg, type_msg);

  return { msg: data.user_msg, data, code }
}

export default codeInterpreter;

