import { html } from 'lit-html';
import styles from './selector.styles.scss';
import WebComponent from 'utils/web-component';

const ComponentTagName = "ml-selector";

class SelectorComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  onInit() {
    document.addEventListener('click', this.handleClickOutside);
  }

  onDestroy() { 
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const path = event.composedPath();
    const element = path.find(element => element.tagName === ComponentTagName.toUpperCase());

    if (element !== this && this.options !== 'none') this.close(); 
  }

  static get observedAttributes() {
    return ['text', 'options', 'show'];
  }

  get text() { return this.getAttribute('text'); }
  set text(value) { this.setAttribute('text', value); }

  get options() { return this.getAttribute('options'); }
  set options(value) { this.setAttribute('options', value); }

  get show() {
    const show = this.getAttribute('show');
    return show === 'true' || show === true;
  }
  set show(value) { this.setAttribute('show', value == 'true' || value == true); }

  close() {
    this.show = false;
  }

  toggle(){
    this.show = !this.show;
    this.dispatchEvent(new CustomEvent('selectorToggle', { 
      detail: { show: this.show },
      bubbles: true,
      composed: true,
    }));
  }

  renderOptions(){
    if(this.options === 'none') return html``;

    return html`
      <div class="options-wrap">
        <div class="flex flex-col gap-2 p-4 bg-white">
          <slot></slot> 
        </div>
      </div>
    `
  }

  template() {
    const show = this.show ?? false;

    return /* template */html`
      <div class="ml-selector-wrap ${show ? 'show' : ''}">
        <div @click="${(e) => this.toggle()}" class="flex items-center cursor-pointer overflow-hidden">
          <p class="label font-bold m-0">${this.text}</p>
          <img src="assets/icons/b/flecha_desplegar.svg" width="40" alt="arrow-icon"/>
        </div>

        ${this.renderOptions()}  
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, SelectorComponent);
