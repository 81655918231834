import { html } from 'lit-html';
import styles from './text-textarea.styles.scss';
import WebComponent from 'utils/web-component';
import "components/atom/icon-button/icon-button.component";


const ComponentTagName = "ml-text-textarea";

class TextTextareaComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.__errorMsg = "";
    this.__editable = false;
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  onInit() { 
    this.root.addEventListener('btnClick', this.handleButtonClick);
    document.addEventListener('click', this.handleClickOutside);
  }

  onDestroy() { 
    this.root.removeEventListener('btnClick', this.handleButtonClick);
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const path = event.composedPath();
    const element = path.find(element => element.tagName === ComponentTagName.toUpperCase());

    if (element !== this && this.options !== 'none'){
      this.__editable = false;
      this.root.querySelector("textarea").value = this.text ?? "";
      this.render();
    }
  }

  handleButtonClick(event) {
    this.__editable = false;
    this.dispatchEvent(new CustomEvent('textEditable', { 
      detail: {
        text: this.root.querySelector("textarea").value,
        type: event.detail.id,
        element: this
      },
      bubbles: true,
      composed: true
    }));

    if(event.detail.id === 'cancel') 
      this.root.querySelector("textarea").value = this.text ?? "";
    this.render();
  }

  static get observedAttributes() {
    return ["text"];
  }

  set text(value) { this.setAttribute("text", value); }
  get text() { return this.getAttribute("text"); }

  __doubleClickHandler(){
    this.__editable = true;
    this.render();
  }

  setError(errorMsg){
    this.__errorMsg = errorMsg;
    this.render();
  }

  template() {
    const editable = this.__editable ?? false;
    const errorMsg = this.__errorMsg ?? "";
    const inputClass = errorMsg.length > 0 ? "error" : "";

    // <p @dblclick="${(e) => this.__doubleClickHandler()}"> ${this.text ?? ""} </p>
    return /* template */html`
      <div class="ml-text-textarea ${ editable ? "editable" : "" }">
        <slot @dblclick="${(e) => this.__doubleClickHandler()}"></slot>

        <div class="editable-area ${inputClass}">
          <textarea> ${this.text ?? ""} </textarea>
          <div class="flex items-center justify-between">
            <span class="error-msg"> ${errorMsg} </span>

            <div class="flex items-center gap-2 ml-auto">
              <ml-icon-button id="confirm" size="md">
                <i class="icon-1 ml-icon-bien-checkbox text-white"></i>
              </ml-icon-button>

              <ml-icon-button id="cancel" color="primary-outline" size="md">
                <i class="icon-1 ml-icon-add_bold rotate-45"></i>
              </ml-icon-button>
            </div>
          </div>
        </div>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, TextTextareaComponent);
