export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  IA: 'ia',
  IA_ERROR: 'ia_error',
}

export class ToastService {

  // @setting: { type, message, time, data }
  static showToast(setting = {}) {
    const toast = document.querySelector("ml-toast");
    if(toast) toast.showToast(setting);
  }
}
