import { html } from 'lit-html';
import styles from './question-card.styles.scss';
import WebComponent from 'utils/web-component';
import { STATE } from 'utils/const';
import { QuestionsApi } from 'api_connection';
import "components/atom/badge/badge.component";
import "components/atom/text-textarea/text-textarea.component";
import "components/atom/text-hover/text-hover.component";
import authReducer from 'storage/reducers/auth';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

const ComponentTagName = "ml-question-card";

class QuestionCardComponent extends WebComponent {

  constructor() {
    super(styles, true, ComponentTagName);
    this.handlerTextEditorEvent = this.handlerTextEditorEvent.bind(this);
  }

  onInit() { 
    this.root.addEventListener('textEditable', this.handlerTextEditorEvent);
  }

  onDestroy() { 
    this.root.removeEventListener('textEditable', this.handlerTextEditorEvent);
  }

  handleSelectorEvent(event) {
    const toggleExplanation = this.root.querySelector('ml-selector');
    const explanationWrap = this.root.querySelector('.explanation-wrap');

    if(toggleExplanation) toggleExplanation.text = event.detail.show ? 'ocultar explicación' : 'mostrar explicación';
    if(explanationWrap) explanationWrap.classList.toggle('fold', !event.detail.show);
  }

  handlerTextEditorEvent(event) {
    const id = event.detail.element.dataset.id;
    const type = event.detail.type;
    const textarea = event.detail.element;

    if(type === 'cancel' || !Boolean(id)) return;
    let { user } = authReducer();

    if(id === 'question') {
      const data = { qid: this.question.qid, qu: event.detail.text.trim() };
      QuestionsApi.write.updateQuestion({ pid: user.pid, ...data },
        (resp) => textarea.firstElementChild.innerText = event.detail.text.trim(),
        (error) => { }
      )
    }else{
      const data = { aid: +id, cnt: event.detail.text.trim() };
      const prefix = textarea.dataset.prefix;
      const text = event.detail.text.trim();
      QuestionsApi.write.updateAnswer({ pid: user.pid, ...data },
        (resp) => textarea.firstElementChild.innerText = `${prefix}. ${text}`,
        (error) => { },
      )
    }
  }

  static get observedAttributes() {
    return ['index', 'question'];
  }

  get index() { return this.getAttribute('index'); }
  set index(value) { this.setAttribute('index', value); }

  get question() { 
    const serializedValue = this.getAttribute('question');
    return JSON.parse(serializedValue);
  }

  set question(value) { 
    if(typeof value == 'string') return;
    const serializedValue = JSON.stringify(value);
    this.setAttribute('question', serializedValue);
  }

  __getExplanation(){
    if (!Boolean(this.question)) return html``;
    let breadcrumbArray = this.question.ibc.split("||");
    let explanation = this.question.ans[0].explanation ?? "";
    explanation = explanation.replaceAll("ʥ", "").replaceAll("\n", "<br>");

    const breadcrumbs = breadcrumbArray.map((breadcrumb, index) => {
      breadcrumb = breadcrumb.split("§")[1];
      const breadcrumbClass = index === breadcrumbArray.length - 1 ? 'semibold' : '';
      const hideImg = index === breadcrumbArray.length - 1 ? 'hidden' : '';
      return /* template */html`
        <div class="breadcrumb-item">
          <ml-text-hover text="${breadcrumb}" font="${breadcrumbClass}" position="bottom">
          </ml-text-hover>
        </div>
        <img src="assets/icons/b/flecha_desplegar.svg" class="breadcrumb-img ${hideImg}" width="20" alt="arrow-icon"/>
      `;
    });

    return { breadcrumbs, explanation }
  }

  __getStateData(){
    if (!Boolean(this.question)) return { name: '', color: 'primary' };

    let states = {};
    states[STATE.DRAFT] = { name: 'borrador', color: 'secondary' };
    states[STATE.REVISION] = { name: 'aprobado', color: 'warning' };
    states[STATE.STAGING] = { name: 'aprobado', color: 'warning' };
    states[STATE.PUBLISHED] = { name: 'publicado', color: 'success' };

    return states[this.question.sts];
  }

  renderAnswers(){
    if(!Boolean(this.question)) return html``;

    const prefixes = ['A', 'B', 'C', 'D', 'E'];
    const answers = this.question.ans.sort(
      (a, b) => (a.must_be_last === b.must_be_last) ? 0 : a.must_be_last ? 1 : -1
    );

    return answers.map((answer, index) => {
      const rightAnswerClass = answer.right_one ? 'right-answer' : '';
      return /* template */html`
        <div class="answer-wrap ${rightAnswerClass}">
          <ml-text-textarea 
            class="question-common-text font-semibold w-full"
            text="${answer.content}"
            data-prefix="${prefixes[index]}"
            data-id="${answer.answer_id}">
            <p class="question-common-text cursor-pointer">
              ${prefixes[index]}. ${answer.content}
            </p>
          </ml-text-textarea>
        </div>
      `;
    });
  }

  template() {
    const explanationData = this.__getExplanation();
    const stateData = this.__getStateData();

    return /* template */html`
      <div class="ml-question-card">
        <div class="flex items-center gap-2 mb-3">
          <ml-badge color="dark"> 
            ${ this.question?.eid == -666 ? 'IA' : 'manual' }
          </ml-badge>
          <ml-badge color="${stateData?.color ?? 'primary'}"> 
            ${stateData?.name ?? ''} 
          </ml-badge>
        </div>

        <ml-text-textarea text="${this.question?.qu ?? ""}" data-id="question">
          <h5 class="m-0 question-title cursor-pointer"> 
            ${this.question?.qu ?? ""} 
          </h5>
        </ml-text-textarea>

        <div class="answers-wrap my-3">
          ${this.renderAnswers()}
        </div>

        <div class="line mb-3"></div>

        <div class="flex justify-end items-center mb-3">
          <ml-selector 
            @selectorToggle="${(e) => this.handleSelectorEvent(e)}"
            show="true"
            text="ocultar explicación" 
            options='none'>
          </ml-selector>
        </div>

        <div class="explanation-wrap">
          <h5 class="m-0 question-title"> Explicación </h5>
          <div class="flex items-center gap-y-1 flex-wrap">
            ${explanationData.breadcrumbs}
          </div>

          <p class="question-common-text mt-0"> 
            ${ unsafeHTML(explanationData.explanation) } 
          </p>
        </div>
      </div>
      `;
  }
}

window.customElements.define(ComponentTagName, QuestionCardComponent);
