import { render, html } from 'lit-html';
import { toCamelCase } from './to-camel-case';

class WebComponent extends HTMLElement {

  constructor(styles, shadowDOM = true, componentTagName = "***") {
    super();
    this.__styles = styles;
    this.__initialized = false;
    this.#__init({ shadowDOM, componentTagName });
  }

  // On create
  connectedCallback() {
    this.__initialized = true;
    this.onInit();
    this.render();
  }

  // On destroy
  disconnectedCallback() { 
    this.onDestroy();
  }

  onInit() { }

  onDestroy() { }

  afterCallback() { }

  /** Triggered when the provided props have changed */
  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (this.__initialized && oldValue !== newValue) {
      this[toCamelCase(attributeName)] = newValue;
      this.render();
    }
  }

  /** ______________________ RENDER ______________________________ */
  template() {
    return /* template */html` `;
  }
  /** ____________________________________________________________ */


  /**
   * ========================= PRIVATE FUNCTIONS ===========================
   * (Don't modify the functions below. Copy-paste to other web components)
   * ==================================================================== */

  /** Init web component config to be executed in constructor */
  #__init({ shadowDOM = true, componentTagName } = {}) {
    this.__isShadowDOM = shadowDOM;

    if (this.__isShadowDOM) this.root = this.attachShadow({ mode: 'open' });
    this.render();
    this.#__attachStyle(componentTagName);
    this.__initialized = false;
  }

  /** Inject processed SCSS Style */
  #__attachStyle(componentTagName) {
    if (componentTagName !== "***") {
      const exist = document.querySelector(`[data-id="_${componentTagName}"]`);
      if (exist) return;
    }

    let style = document.createElement('style');
    style.dataset.id = "_" + componentTagName;
    style.textContent = this.__styles
      .replace(/(\/)([\w?\d?])/g, '\\/$2')              // Fix for tailwind 'w-1/2' classes
      .replace(/(\[)(\w?\d?(?:rem|px|%)?)/g, '\\[$2\\') // Fix for tailwind custom 'w-[50px]' classes
      .replace(/(\.\w+)(\:)(\w+)/g, '$1\\:$3');         // Fix for tailwind state classes 'hover:bg-primay'

    const appendStyleTo = this.__isShadowDOM
      ? this.root
      : document.head || document.getElementsByTagName('head')[0];

    appendStyleTo.prepend(style);
  }


  /** Internal function to update the UI based (re-render and first render) */
  render() {
    const attachTo = this.__isShadowDOM ? this.root : this;
    render(this.template(), attachTo);
    this.afterCallback();
  }
  /** ============================================================= */
}

export default WebComponent;
