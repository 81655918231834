import HandleRequest from './../../handle_request';
import { prg_pf as prpf } from './../../urls';
import { obj } from './../../urls';
import ApiService from './../../api_service';
import { formatPrPfInfo, formatJson, formatPfList } from './format';
import { checkPrivileges } from '../utils';
import Privilege from 'privilege_manager/privileges';
import { alert, dialog } from 'components/modals';

const changeOwner = (runApi) => {
  dialog({
    title: 'Este trabajo no te pertenece',
    body: '¿Deseas tomar posesión de este trabajo para continuar?',
    btn_ok: 'tomar posesión',
    ok: runApi
  })
}

const checkOwner = (data, success, error, _for, api) => {
  let { coo, create_programs } = Privilege;
  checkPrivileges([coo, create_programs], (resp) => {
    let { create_programs, change_object_ownership } = resp;
    let { old_pid, pid } = data;
    if(create_programs.permit && ((old_pid == pid) || change_object_ownership.permit)){
      if(old_pid != pid){
        changeOwner(() => {
          data = { ...data, tow: true }
          api(data, success, error);
        })
      }else{
        api(data, success, error);
      }
    }else{
      alert({
        title: 'Permiso denegado',
        body: `Lo sentimos, no tienes el permiso necesario ${_for}.`,
        btn_ok: 'salir'
      })
    }
  }, data.pid)
}

export const getPrPfInfo = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.getPrPfInfo, data),
    (dataSuccess) => {
      let data = formatPrPfInfo(dataSuccess.getPrPfInfo);
      success(data)
    }, (dataError) => error(dataError),
    { api: prpf.getPrPfInfo, data }
  );
}

/**
 * Description: only for "create_programs" privilege
 * For change ownership it must have "change_object_ownership" privilege
 * */
export const updatePrPfInfo = (data, success, error) => {
  checkOwner(data, success, error, 'para actualizar los datos', _updatePrPfInfo);
}

const _updatePrPfInfo = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.updatePrPfInfo, data),
    (dataSuccess) => {
      let data = formatPrPfInfo(dataSuccess.updatePrPfInfo);
      success(data)
    }, (dataError) => error(dataError),
    { api: prpf.updatePrPfInfo, data }
  );
}

export const getTree = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(obj.getTree, data),
    (s_data) => {
      success({ items: s_data.getTree.tree });
    }, (e) => error(e),
    { api: obj.getTree, data }
  )
}

/**
 * Description: only for "create_programs" privilege
 * For change ownership it must have "change_object_ownership" privilege
 * */
export const createItemPrPf = (data, success, error) => {
  checkOwner(data, success, error, 'para crear ítems', _createItemPrPf);
}

const _createItemPrPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.createItemPrPf, data),
    (dataSuccess) => success(dataSuccess.createItemPrPf),
    (e) => error(e),
    { api: prpf.createItemPrPf, data }
  )
}

/**
 * Description: only for "create_programs" privilege
 * For change ownership it must have "change_object_ownership" privilege
 * */
export const updateItemPrPf = (data, success, error) => {
  checkOwner(data, success, error, 'para actualizar ítems', _updateItemPrPf);
}

const _updateItemPrPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.updateItemPrPf, data),
    (dataSuccess) => {
      let response = dataSuccess.updateItemPrPf;
      success({ ...response, cod: 'ok' })
    }, (e) => error(e),
    { api: prpf.updateItemPrPf, data }
  )
}

/**
 * Description: only for "create_programs" privilege
 * For change ownership it must have "change_object_ownership" privilege
 * */
export const deleteItemPrPf = (data, success, error) => {
  checkOwner(data, success, error, 'para remover ítems', _deleteItemPrPf);
}

const _deleteItemPrPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.deleteItemPrPf, data),
    (successData) => success(successData),
    (e) => error(e),
    { api: prpf.deleteItemPrPf, data }
  )
}

/**
 * Description: only for "create_programs" privilege
 * For change ownership it must have "change_object_ownership" privilege
 * */
export const convertToPrefab = (data, success, error) => {
  checkOwner(data, success, error, 'para convertir a prefab', _convertToPrefab);
}

const _convertToPrefab = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.convertToPrefab, data),
    (successData) => success(successData.convertToPrefab),
    (e) => error(e),
    { api: prpf.convertToPrefab, data }
  )
}

export const clonePrefab = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.clonePrefab, data),
    (s_data) => success(s_data.clonePrefab),
    (e_data) => error(e_data),
    { api: prpf.clonePrefab, data }
  )
}

export const getKbList = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.getKbList, data),
    (s_data) => success(s_data.getKbList),
    (e_data) => error(e_data),
    { api: prpf.getKbList, data }
  )
}

export const getPrefabList = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.getPrefabList, data),
    (s_data) => {
      let list = formatPfList(s_data.getPrefabList.list)
      let resp = { ...s_data.getPrefabList, list }
      success(resp);
    },
    (e_data) => error(e_data),
    { api: prpf.getPrefabList, data }
  )
}

export const revisionPrPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.revisionPrPf, data),
    (s_data) => success(s_data.revisionPrPf),
    (e_data) => error(e_data),
    { api: prpf.revisionPrPf, data }
  )
}

export const rejectPrPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.rejectPrPf, data),
    (s_data) => success(s_data.rejectPrPf),
    (e_data) => error(e_data),
    { api: prpf.rejectPrPf, data }
  )
}

export const publishPrPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.publishPrPf, data),
    (s_data) => success(s_data.publishPrPf),
    (e_data) => error(e_data),
    { api: prpf.publishPrPf, data }
  )
}

/**
 * Description: only for "create_programs" privilege
 * For change ownership it must have "change_object_ownership" privilege
 * */
export const unlinkNodeFromPf = (data, success, error) => {
  checkOwner(data, success, error, 'para desvincular ítems', _unlinkNodeFromPf);
}

const _unlinkNodeFromPf = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(prpf.unlinkNodeFromPf, data),
    (s_data) => { success(s_data.unlinkNodeFromPf) },
    (e_data) => error(e_data),
    { api: prpf.unlinkNodeFromPf, data }
  ) 
}

