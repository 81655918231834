import HandleRequest from './../../handle_request';
import ApiService from './../../api_service';
import { questions as url } from './../../urls';

export const updateQuestion = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.updateQuestion, data),
    (s_data) => { if (success) success(s_data); },
    (s_error) => { if (error) error(s_error); },
    { api: url.updateQuestion, data }
  )
}

export const createAnswer = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.createAnswer, data),
    (s_data) => { if (success) success(s_data) },
    (s_error) => { if (error) error(s_error) },
    { api: url.createAnswer, data }
  )
}

export const deleteQuestion = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.deleteQuestion, data),
    (s_data) => { if(success) success(s_data) },
    (s_error) => { if(error) error(s_error) },
    { api: url.deleteQuestion, data }
  )
}

export const updateAnswer = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.updateAnswer, data),
    (s_data) => { if (success) success(s_data); },
    (s_error) => { if (error) error(s_error); },
    { api: url.updateAnswer, data }
  )
}

export const createQuestion = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.createQuestion, data),
    (s_data) => { if (success) success(s_data.createQuestion) },
    (s_error) => { if (error) error(s_error) },
    { api: url.createQuestion, data }
  )
}

export const createReference = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.createReference, data),
    (s_data) => { if (success) success(s_data.createReference) },
    (s_error) => { if (error) error(s_error) },
    { api: url.createReference, data }
  )
}

export const readyQuestion = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.readyQuestion, data),
    (s_data) => { if (success) success(s_data.revisionQuestion); },
    (s_error) => { if (error) error(s_error); },
    { api: url.readyQuestion, data }
  )
}

export const revisionQuestion = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.revisionQuestion, data),
    (s_data) => { if (success) success(s_data.revisionQuestion); },
    (s_error) => { if (error) error(s_error); },
    { api: url.revisionQuestion, data }
  )
}

export const updateReference = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.updateReference, data),
    (s_data) => { if (success) success(s_data) },
    (s_error) => { if (error) error(s_error) },
    { api: url.updateReference, data }
  )
}

export const deleteReference = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.deleteReference, data),
    (s_data) => { if (success) success(s_data) },
    (s_error) => { if (error) error(s_error) },
    { api: url.deleteReference, data }
  )
}

export const deleteQsFromKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.deleteQsFromKb, data),
    (s_data) => success(s_data),
    (s_error) => error(s_error),
    { api: url.deleteQsFromKb, data }
  )
}

export const readyQsFromKb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.readyQsFromKb, data),
    (s_data) => { if (success) success(s_data); }, 
    (s_error) => { if(error) error(s_error); },
    { api: url.readyQsFromKb, data }
  )
}


export const publishQsFromKb = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.publishQsFromKb, data),
    (s_data) => success(s_data),
    (s_error) => error(s_error),
    { api: url.publishQsFromKb, data }
  )
}

export const rejectQuestion = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.rejectQuestion, data),
    (s_data) => { if (success) success(s_data); },
    (s_error) => { if (error) error(s_error); },
    { api: url.rejectQuestion, data }
  )
}

export const impCreateQuestion = (data, success, error) => {
  HandleRequest(
    () => ApiService.post(url.impCreateQuestion, data),
    (s_data) => success(s_data),
    (s_error) => error(s_error),
    { api: url.impCreateQuestion, data }
  )
}

export const createAIQuestionsFromKb = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.createAIQuestionsFromKb, data),
    (s_data) => { if (success) success(s_data) },
    (s_error) => { if (error) error(s_error) },
    { api: url.createAIQuestionsFromKb, data }
  )
}

export const createAIQuestionsPh = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.createAIQuestionsPh, data),
    (s_data) => { if (success) success(s_data) },
    (s_error) => { if (error) error(s_error) },
    { api: url.createAIQuestionsPh, data }
  )
}

export const setWorkerAsSeen = (data, success, error) => {
  return HandleRequest(
    () => ApiService.post(url.setWorkerAsSeen, data),
    (s_data) => { if (success) success(s_data.setWorkerAsSeen); },
    (e_data) => { if (error) error(e_data); },
    { api: url.setWorkerAsSeen, data }
  )
}
