// Reference : https://docs.google.com/spreadsheets/d/1kLEuYHcQk8nsicVG8oG839Zjz9jnGl97q_d2Rd9q2f4/edit#gid=0

export default {
  login_cms: 'login_cms',
  create_boe: 'create_boe',
  boe_kb: 'boe_kb',
  create_kb: 'create_kb',
  standard_kb: 'standard_kb',
  programs: 'programs',
  create_programs: 'create_programs',
  questions: 'questions',
  create_questions: 'create_questions',
  programs: 'programs',
  create_programs: 'create_programs',
  coo: 'change_object_ownership'
}

